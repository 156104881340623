import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Card, CardContent } from '@material-ui/core';
import { RecentTrxActivities } from '../lease/recentActivity';
import { TabComponent, TabSection } from './tabs';
import { RootStoreContext } from '../../global/storeContext';
import { PendingReviewsCard } from '../reviews/pendingReviewsCard';

export const ActivityCard: React.FC = observer(() => {
    const { ratingStore, uiStore } = React.useContext(RootStoreContext);

    useEffect(() => {
        ratingStore.getPendingReviews(uiStore.isLandlordMode());
    }, []);

    const sections: TabSection[] = [
        {
            key: 'RecentActivity',
            title: 'Recent Activity',
            component: <RecentTrxActivities />,
        },
        {
            key: 'PendingReviews',
            title: `Pending Reviews (${ratingStore.PendingReviews.length})`,
            component: <PendingReviewsCard />,
        },
    ];
    return (
        <Card style={{ paddingTop: '10px', paddingRight: '10px' }}>
            <CardContent>
                <TabComponent
                    sections={sections}
                    onSubmit={() => {}}
                    hideStepperButtons={true}
                    disableSave
                />
            </CardContent>
        </Card>
    );
});
