import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { GridContent, GridMainContent, GridSideContent } from '../uiComponents/UIComponents';
import { Divider, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { AddressesForm, AddressFormBeta, AddressViewerAddress } from '../address/addressForm';
import { IResidenceInfo, ITenantProfile } from 'realhaus-sdk';
import { RootStoreContext } from '../../global/storeContext';
import { fetchPreviousAddresses } from '../../utils/address';

interface AddressInfoComponentProps {
    profile: ITenantProfile;
    updateProfile: (
        prop: keyof ITenantProfile,
    ) => (value: IResidenceInfo | IResidenceInfo[] | null) => void;
}

export const AddressInfoComponent: React.FC<AddressInfoComponentProps> = observer(
    ({ profile, updateProfile }) => {
        const classes = useStyles();
        const { leaseStore, userStore } = useContext(RootStoreContext);
        const [previousAddresses, setPreviousAddresses] = useState<AddressViewerAddress[]>([]);

        const fetchHistoricalAddresses = () =>
            fetchPreviousAddresses(
                userStore.userId ?? '',
                leaseStore,
                profile.previousNonRentalAddresses ?? [],
            );

        useEffect(() => {
            fetchHistoricalAddresses().then((addresses) => {
                setPreviousAddresses(addresses);
            });
        }, [profile, leaseStore]);
        return (
            <>
                <GridContent spacing={5}>
                    <GridMainContent>
                        <Typography variant='h5' gutterBottom>
                            Where do you currently live. Where have you lived in the past?
                        </Typography>
                    </GridMainContent>

                    <GridMainContent withSidebar>
                        {/* Section: Current Address */}
                        <AddressFormBeta
                            title='Current Address'
                            address={profile.address}
                            setAddress={updateProfile('address')}
                        />

                        <Divider variant='middle' className={classes.divider} />
                        {/* Section: Previous Addresses */}
                        <AddressesForm
                            title='Past Addresses'
                            addresses={previousAddresses}
                            onAddressCreated={(address) => {
                                if (address.landlordInfo) {
                                    leaseStore
                                        .addNewLeaseAgreementByTenant(address.landlordInfo, {
                                            address: address.address,
                                            leaseStartDate: address.moveinDate,
                                            leaseEndDate: address.moveoutDate ?? 0,
                                        })
                                        .then(() => {
                                            fetchHistoricalAddresses();
                                        });
                                } else {
                                    const prevNonRentalAddresses = [
                                        ...(profile.previousNonRentalAddresses ?? []),
                                        {
                                            address: address.address,
                                            moveinDate: address.moveinDate,
                                            moveoutDate: address.moveoutDate,
                                        },
                                    ];
                                    updateProfile('previousNonRentalAddresses')(
                                        prevNonRentalAddresses,
                                    );
                                    fetchHistoricalAddresses();
                                }
                            }}
                            onAddressDeleted={(address) => {
                                const prevNonRentalAddresses = [
                                    ...(profile.previousNonRentalAddresses ?? []).filter(
                                        (x) =>
                                            x.moveinDate !== address.moveinDate &&
                                            x.moveoutDate !== address.moveoutDate,
                                    ),
                                ];

                                updateProfile('previousNonRentalAddresses')(prevNonRentalAddresses);
                                fetchHistoricalAddresses();
                            }}
                        />
                    </GridMainContent>
                    <GridSideContent>
                        <Typography variant='body1' align='justify'>
                            In this section, please provide accurate details about your current and
                            previous residences. This information helps the landlord better
                            understand your rental history and ensure a smooth application process.
                            Here's what to include: <br />
                            <b>Current Address:</b>
                            <br />
                            Provide the full address where you are currently living and the date you
                            moved in.
                            <b>Previous Addresses (Last 3 Years):</b>
                            <br />
                            List all the addresses where you have resided in the past three years,
                            starting with the most recent. For each location, include the full
                            address and the dates you moved into and out of each address.
                            <br />
                            <br />
                            Double-check the addresses to ensure they are complete, including unit
                            numbers if applicable. Be as precise as possible with the move-in and
                            move-out dates, specifying the month and year. If you are unsure about
                            specific dates, approximate them as closely as possible. Providing
                            complete and accurate information will help avoid delays in processing
                            your application.
                        </Typography>
                    </GridSideContent>
                </GridContent>
            </>
        );
    },
);
