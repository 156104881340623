import { Grid, Paper, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';
import { IWallet } from 'realhaus-sdk';
import { RootStoreContext } from '../../global/storeContext';
import { useStyles } from './styles';

export const WalletSummaryText: React.FC = observer(() => {
    const { walletStore } = useContext(RootStoreContext);
    const [wallet, setWallet] = useState<IWallet | undefined>();

    useEffect(() => {
        const init = async () => {
            const w = await walletStore.getWallet();
            setWallet(w);
        };
        init();
    }, []);

    const accountBalance = wallet?.amount ?? 0;
    return (
        <>
            <Grid container spacing={3} style={{ paddingBottom: '10px' }}>
                <Grid item xs={12} style={{ textAlign: 'right' }}>
                    <Typography
                        variant='button'
                        gutterBottom
                        style={{ paddingRight: '8px' }}
                        display='inline'
                    >
                        ACCOUNT BALANCE:
                    </Typography>
                    <Typography
                        variant='body1'
                        color='primary'
                        gutterBottom
                        style={{ paddingRight: '14px' }}
                        display='inline'
                    >
                        <strong> $ {accountBalance.toFixed(2)} CAD</strong>
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
});

export const WalletWidget: React.FC = observer(() => {
    const classes = useStyles();
    const { walletStore } = useContext(RootStoreContext);
    const [wallet, setWallet] = useState<IWallet | undefined>();

    useEffect(() => {
        const init = async () => {
            const w = await walletStore.getWallet();
            setWallet(w);

            console.log(w);
        };
        init();
    }, []);
    const accountBalance = wallet?.amount ?? 0;

    return (
        <>
            <Paper variant='elevation'>
                <Grid container>
                    <Grid item xs={12}>
                        <div className={classes.alignCenter}>
                            <Typography variant='subtitle2' color='textSecondary' gutterBottom>
                                Account BALANCE
                            </Typography>
                            <Typography variant='h5' gutterBottom style={{ padding: '10px' }}>
                                $ {accountBalance.toFixed(2)}{' '}
                                <Typography variant='caption' display='inline'>
                                    CAD
                                </Typography>
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
});
