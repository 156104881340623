import React from 'react';
import { observer } from 'mobx-react-lite';
import { IOccupation, ITenantProfile } from 'realhaus-sdk';
import { TenantEmployment } from '../tenantProfile/tenantEmployment';
import { GridContent, GridMainContent, GridSideContent } from '../uiComponents/UIComponents';
import { Typography } from '@material-ui/core';

interface FinanceInfoProps {
    profile: ITenantProfile;
    updateProfile: (prop: keyof ITenantProfile) => (value: IOccupation) => void;
}

export const FinanceInfoComponent: React.FC<FinanceInfoProps> = observer(
    ({ profile, updateProfile }) => {
        return (
            <>
                <GridContent spacing={5}>
                    <GridMainContent>
                        <Typography variant='h5' gutterBottom>
                            What is your financial situation?
                        </Typography>
                    </GridMainContent>
                    <GridMainContent withSidebar>
                        <TenantEmployment
                            employment={profile.occupation}
                            updateEmployment={(prop: keyof IOccupation) => (ev) => {
                                updateProfile('occupation')({
                                    ...profile.occupation,
                                    [prop]: ev.target.value,
                                });
                            }}
                        />
                    </GridMainContent>
                    <GridSideContent>
                        <Typography variant='body1' align='justify'>
                            In this section, please provide accurate details about your current
                            employment. This information helps the landlord to understand your
                            financial status and decide if you will be able to meet the rent of the
                            property. 
                        </Typography>
                    </GridSideContent>
                </GridContent>
            </>
        );
    },
);
