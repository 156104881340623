import { CreditReportStore } from './creditReportStore';
import FirebaseStore from './firebaseStore';
import { GoogleMapsApiStore } from './googleMapsApiStore';
import { IDVerificationStore } from './idVerificationStore';
import { LeaseAgreementStore } from './leaseAgreementStore';
import { LeaseStore } from './leaseStore';
import { ListingStore } from './listingStore';
import { MessageStore } from './messageStore';
import { RatingStore } from './ratingStore';
import { ShowingStore } from './showingStore';
import { TenantStore } from './tenantStore';
import { UIStore } from './uiStore';
import { UserStore } from './userStore';
import { BankAccountStore } from './bankStore';
import { WalletStore } from './walletStore';
import { AnalyticsStore } from './analyticsStore';


export class RootStore {
  private firebaseStore: FirebaseStore;

  public userStore: UserStore;
  public listingStore: ListingStore;
  public uiStore: UIStore;
  public tenantStore: TenantStore;
  public showingStore: ShowingStore;
  public leaseStore: LeaseStore;
  public googleMapStore: GoogleMapsApiStore;
  public creditReportStore: CreditReportStore;
  public messageStore: MessageStore;
  public ratingStore: RatingStore;
  public leaseAgreementStore: LeaseAgreementStore;
  public bankAccountStore: BankAccountStore
  public idVerificationStore: IDVerificationStore;
  public walletStore: WalletStore;
  public analyticsStore: AnalyticsStore;

  constructor() {
    this.uiStore = new UIStore();
    this.firebaseStore = new FirebaseStore();
    this.analyticsStore = new AnalyticsStore();
    this.userStore = new UserStore(this.firebaseStore, this.analyticsStore);
    this.idVerificationStore = new IDVerificationStore(this.firebaseStore, this.userStore);
    this.listingStore = new ListingStore(this.firebaseStore);
    this.tenantStore = new TenantStore(this.firebaseStore);
    this.showingStore = new ShowingStore(this.firebaseStore, this.uiStore, this.userStore);
    this.leaseStore = new LeaseStore(this.firebaseStore, this.listingStore, this.userStore);
    this.googleMapStore = new GoogleMapsApiStore();
    this.creditReportStore = new CreditReportStore(this.firebaseStore);
    this.messageStore = new MessageStore(this.firebaseStore, this.userStore);
    this.ratingStore = new RatingStore(this.firebaseStore, this.tenantStore, this.userStore);
    this.messageStore = new MessageStore(this.firebaseStore, this.userStore);
    this.leaseAgreementStore = new LeaseAgreementStore();
    this.bankAccountStore = new BankAccountStore(this.firebaseStore, this.userStore)
    this.walletStore = new WalletStore(this.firebaseStore, this.userStore);
  }
}
export const rootStore = new RootStore();