import React from 'react';
import {
    Box,
    Card,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { format } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import {
    IDTransaction,
    IDTransactionLog,
    IWallet,
    TransactionStatus,
    TransactionType,
    TrxLogType,
} from 'realhaus-sdk';
import { NumericFormat } from 'react-number-format';
import { RootStoreContext } from '../../global/storeContext';
import { DocumentData, QueryDocumentSnapshot } from 'firebase/firestore';
import { DEFAULT_TRANSACTIONS_LIMIT } from '../../stores/walletStore';
import { useStyles } from './styles';
import StringUtils from '../../utils/string';
import { useIsMobile } from '../uiComponents/UIComponents';
import { Stack } from '@mui/material';
import { IsSystemWallet } from '../../global/util';

const CurrencyFormatCAD = ({ amount }: { amount: number }) => {
    return (
        <NumericFormat
            value={amount}
            prefix='$ '
            suffix=' CAD'
            displayType={'text'}
            thousandSeparator={true}
            decimalSeparator='.'
            fixedDecimalScale
            decimalScale={2}
        />
    );
};
export const AccountTransactionsView: React.FC<{
    transactions: IDTransactionLog[];
    canFetchMoreTransaction: boolean;
    getTransactions: () => Promise<void>;
}> = observer(({ transactions, canFetchMoreTransaction, getTransactions }) => {
    const classes = useStyles();
    const isMobile = useIsMobile();
    const { userStore } = React.useContext(RootStoreContext);

    const trxStatusColor = (status: TransactionStatus) => {
        switch (status) {
            case TransactionStatus.SUCCESS:
                return 'green';
            case TransactionStatus.FAILED:
                return 'red';
            default:
                return '#ff9e00';
        }
    };

    const isReceiver = (trnx: IDTransactionLog) => trnx.receiverId === userStore.userId;
    const isSender = (trnx: IDTransactionLog) => trnx.senderId === userStore.userId;
    const getTrnxSummaryText = (trnx: IDTransactionLog) => {
        if (trnx.type === TrxLogType.PAYMENT) {
            if (!!trnx.meta?.billId && !!trnx.meta?.leaseId) {
                if (isReceiver(trnx)) {
                    // received bill payment
                    return 'Received Bill Payment';
                } else if (isSender(trnx)) {
                    return 'Bill Payment';
                } else {
                    return 'N/A';
                }
            }
        } else if (trnx.type === TrxLogType.WITHDRAW) {
            return trnx.narration;
        } else if (trnx.type === TrxLogType.TRANSFER) {
            if (isReceiver(trnx)) {
                // received bill payment
                return 'Received Funds Transfer';
            } else if (isSender(trnx)) {
                return IsSystemWallet(trnx.receiverId) ? trnx.narration : 'Transferred Funds';
            } else {
                return 'N/A';
            }
        }

        return '';
    };

    const TrnxAmount = ({ trnx }: { trnx: IDTransactionLog }) => {
        let amount = trnx.amount;
        if (isReceiver(trnx) && trnx.type === TrxLogType.PAYMENT) {
            amount = trnx.amount - (trnx.meta?.trxfees ?? 0);
        }

        return <CurrencyFormatCAD amount={amount} />;
    };
    return (
        <>
            <Card variant='outlined' elevation={0}>
                <Box className={classes.container}>
                    <List>
                        {transactions.map((tranx, index) => (
                            <>
                                <ListItem>
                                    <ListItemText
                                        primary={
                                            <Typography variant='body2'>
                                                {format(tranx.timestamp, 'PPp')}
                                            </Typography>
                                        }
                                        secondary={
                                            <>
                                                <Typography
                                                    variant='body1'
                                                    noWrap
                                                    style={{ color: 'black' }}
                                                >
                                                    {isMobile
                                                        ? StringUtils.truncateString(
                                                              getTrnxSummaryText(tranx),
                                                              22,
                                                          )
                                                        : StringUtils.truncateString(
                                                              getTrnxSummaryText(tranx),
                                                          )}
                                                </Typography>
                                                <Typography variant='caption'>
                                                    {tranx.id}
                                                </Typography>
                                            </>
                                        }
                                    />
                                    <ListItemSecondaryAction>
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    variant='body1'
                                                    style={{ fontWeight: 'bold' }}
                                                >
                                                    <TrnxAmount trnx={tranx} />
                                                </Typography>
                                            }
                                            secondary={
                                                <Typography
                                                    variant='body1'
                                                    noWrap
                                                    style={{
                                                        color: trxStatusColor(tranx.status),
                                                    }}
                                                >
                                                    {tranx.status}
                                                </Typography>
                                            }
                                        ></ListItemText>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider />
                            </>
                        ))}
                        <ListItem
                            button
                            onClick={getTransactions}
                            disabled={!canFetchMoreTransaction}
                        >
                            <ListItemText inset primary=' Load more transactions...' />
                        </ListItem>
                    </List>
                </Box>
            </Card>
        </>
    );
});

export const WalletComponent: React.FC = observer(() => {
    const { uiStore, walletStore } = useContext(RootStoreContext);
    const [wallet, setWallet] = useState<IWallet | undefined>();
    const [trxRecords, setTrxRecords] = useState<IDTransactionLog[]>([]);
    const [lastQueryDoc, setLastQueryDoc] =
        React.useState<QueryDocumentSnapshot<DocumentData> | null>(null);
    const [canFetchMoreTransactions, setCanFetchMoreTransactions] = React.useState<boolean>(false);

    const getTrxActivities = async () => {
        uiStore.showLoading();
        try {
            // fetch transactions
            const activities = await walletStore.getTrxActivities(lastQueryDoc);
            setCanFetchMoreTransactions(
                !!activities && activities.transactions.length == DEFAULT_TRANSACTIONS_LIMIT,
            );

            if (!activities || activities.transactions.length === 0) {
                return;
            }

            setLastQueryDoc(activities.lastDoc);
            const t = activities.transactions;
            setTrxRecords([...trxRecords, ...t]);
        } catch (err) {
            console.error('== Error fetching transaction activities');
            console.error(err);
        } finally {
            uiStore.hideLoading();
        }
    };

    const getWallet = async () => {
        const w = await walletStore.getWallet();
        setWallet(w);
    };
    useEffect(() => {
        const init = async () => {
            uiStore.showLoading();
            try {
                // fetch wallet
                await Promise.allSettled([getWallet(), getTrxActivities()]);
            } finally {
                uiStore.hideLoading();
            }
        };

        init();
    }, []);

    return (
        <>
            <Grid
                container
                justifyContent='space-between'
                style={{ marginTop: '24px', marginBottom: '40px' }}
                spacing={2}
            >
                <Grid item>
                    <div>
                        <Typography variant='body1' color='textSecondary' display='inline'>
                            BALANCE:
                        </Typography>
                        <Typography
                            variant='body1'
                            color='primary'
                            style={{ paddingLeft: '10px', fontWeight: 'bold' }}
                            display='inline'
                        >
                            <strong>
                                <CurrencyFormatCAD amount={wallet?.amount ?? 0} />
                            </strong>
                        </Typography>
                    </div>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant='h6'>Recent Transactions</Typography>
                </Grid>
                <Grid item xs={12}>
                    <AccountTransactionsView
                        transactions={trxRecords}
                        canFetchMoreTransaction={canFetchMoreTransactions}
                        getTransactions={getTrxActivities}
                    />
                </Grid>
            </Grid>
        </>
    );
});
