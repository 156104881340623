import { FirebaseApp, initializeApp } from 'firebase/app';
import {
  collection,
  Firestore,
  getDoc,
  doc,
  DocumentData,
  Timestamp,
  setDoc,
  getDocs,
  addDoc,
  query,
  where,
  QueryConstraint,
  limit,
  orderBy,
  deleteDoc,
  initializeFirestore,
  onSnapshot, QuerySnapshot,
  connectFirestoreEmulator,
  startAfter,
  documentId,
  DocumentSnapshot,
  updateDoc,
  arrayRemove,
  or,
  QueryFilterConstraint,
  QueryFieldFilterConstraint,
  QueryCompositeFilterConstraint,
  QueryNonFilterConstraint,
  and
} from 'firebase/firestore';
import {
  getStorage,
  FirebaseStorage,
  ref as storageRef,
  uploadBytes,
  UploadMetadata,
  getDownloadURL,
  deleteObject,
  connectStorageEmulator,
} from 'firebase/storage';
import { makeAutoObservable } from 'mobx';
import { firebaseConfig } from '../firebase.config';
import {
  Auth,
  getAuth,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  updatePassword,
  sendEmailVerification,
  User,
  applyActionCode,
  reauthenticateWithCredential,
  EmailAuthProvider,
  verifyBeforeUpdateEmail,
  updateProfile,
  connectAuthEmulator,
} from 'firebase/auth';
import { Analytics, initializeAnalytics } from 'firebase/analytics';
import {
  AndQueryOperation,
  Collections,
  DBStore,
  LimitQueryOperation,
  OrderByQueryOperation,
  OrQueryOperation,
  QueryOperation,
  StartAfterQueryOperation,
  WhereQueryOperation,
} from './dbStore';
import { AuthErrorCodesEx } from '../constants/authErrors';
import { FirebaseAuthPort, FirebaseDBPort, FirebaseStoragePort, UseFirebaseEmulator } from '../appConfig';

class FirebaseStore implements DBStore {
  // app
  firebaseApp: FirebaseApp;

  authService: Auth;
  dbService: Firestore;
  storageService: FirebaseStorage;
  analytics: Analytics;

  constructor() {
    makeAutoObservable(this);
    this.firebaseApp = initializeApp(firebaseConfig);

    /* Firebase APIs */
    this.analytics = initializeAnalytics(this.firebaseApp);
    // this.analytics = getAnalytics(this.firebaseApp);
    this.authService = getAuth(this.firebaseApp);
    this.dbService = initializeFirestore(this.firebaseApp, { ignoreUndefinedProperties: true });
    this.storageService = getStorage(this.firebaseApp);

    this.connectToEmulator();
  }

  connectToEmulator() {
    if (UseFirebaseEmulator) {
      connectAuthEmulator(this.authService, `http://127.0.0.1:${FirebaseAuthPort}`);
      connectFirestoreEmulator(this.dbService, '127.0.0.1', FirebaseDBPort);
      connectStorageEmulator(this.storageService, '127.0.0.1', FirebaseStoragePort)
    }
  }

  createUserWithEmailAndPassword = (email: string, password: string) =>
    createUserWithEmailAndPassword(this.authService, email, password);

  signInWithEmailAndPassword = (email: string, password: string) =>
    signInWithEmailAndPassword(this.authService, email, password);

  signOut = () => this.authService.signOut();

  doPasswordReset = (email: string) => sendPasswordResetEmail(this.authService, email);

  doPasswordUpdate = (password: string) => {
    if (!!this.authService.currentUser) updatePassword(this.authService.currentUser, password);
  };

  doEmailUpdate = (newEmail: string) => {
    if (!!this.authService.currentUser) verifyBeforeUpdateEmail(this.authService.currentUser, newEmail);
  }

  doProfilePhotoUpdate = (url: string) => {
    if (!!this.authService.currentUser) updateProfile(this.authService.currentUser, { photoURL: url });
  }

  reauthenticatePassword = (oldPassword: string) => {
    let user = this.authService.currentUser;
    if (!user || !user.email) {
      const err = { code: AuthErrorCodesEx.USER_NOT_AUTHORIZED };
      throw err;
    }

    const cred = EmailAuthProvider.credential(user.email, oldPassword)
    return reauthenticateWithCredential(user, cred);
  }

  doSendEmailVerification = async (user: User) => {
    if (!!user || !!this.authService.currentUser) {
      var actionCodeSettings = {
        url:
          process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT ??
          `${window.location.origin}`,
        handleCodeInApp: true,
      };
      console.log(actionCodeSettings);
      await sendEmailVerification(user ?? this.authService.currentUser, actionCodeSettings);
    }
  };

  applyVerificationCode = (code: string) => applyActionCode(this.authService, code);

  onAuthUserListener = (next: any, fallback: any) =>
    onAuthStateChanged(this.authService, (authUser) => {
      if (authUser) {
        const u = {
          uid: authUser?.uid ?? '',
          email: authUser?.email ?? '',
          emailVerified: authUser?.emailVerified ?? false,
          providerData: authUser?.providerData ?? [],
        };

        var userRef = this.user(authUser.uid);
        if (userRef) {
          userRef
            .then((doc) => {
              if (doc.exists()) {
                const dbUser = doc.data() as DocumentData;

                // default empty roles
                if (!dbUser.roles) {
                  dbUser.roles = {};
                }

                // merge auth and db user
                next({ ...u, ...dbUser });
              } else {
                next(u);
              }
            })
            .catch((error) => {
              console.log('Error getting document:', error);
              // user is logged in
              next(u);
            });
        } else {
          next(u);
        }
      } else {
        fallback();
      }
    });

  // *** User API ***
  user = (uid: string) => getDoc(this.getDocumentRef(Collections.users, `${uid}`));
  updateUser = (uid: string, data: any) =>
    setDoc(this.getDocumentRef(Collections.users, uid), data, { merge: true });
  users = () => collection(this.dbService, Collections.users);

  addDocument = (path: string, data: any) => addDoc(collection(this.dbService, path), data);
  addDocumentWithId = (path: string, id: string, data: any) =>
    setDoc(this.getDocumentRef(path, id), data, { merge: true });
  getDocumentRef = (collectionPath: string, docId: string) =>
    doc(collection(this.dbService, collectionPath), docId);
  getDocument = (collectionPath: string, docId: string) =>
    getDoc(this.getDocumentRef(collectionPath, docId));

  findDocuments = (path: string, queries: QueryOperation[]) => {
    const qConstraints = queries.filter(q => ['or', 'where', 'and'].includes(q.type));
    const nonQConstraints = queries.filter(q => ['limit', 'orderBy', 'startAfter'].includes(q.type));

    if (qConstraints.length > 1) {
      // compound filter query
      const qC = qConstraints.map(q => this.toQueryFilterConstraint(q));
      const nonQC = nonQConstraints.map(q => this.toNonQueryFilterConstraint(q));

      return getDocs(
        query(
          collection(this.dbService, path),
          and(...qC),
          ...nonQC,
        )
      );
    } else if (qConstraints.length === 1 && qConstraints[0].type !== 'where') {
      const qC = this.toQueryFilterConstraint(qConstraints[0]);
      return getDocs(
        query(
          collection(this.dbService, path),
          qC as QueryCompositeFilterConstraint,
          ...nonQConstraints.map(q => this.toNonQueryFilterConstraint(q))
        )
      );
    } else {
      return getDocs(
        query(
          collection(this.dbService, path),
          ...queries.map((q) => this.toQueryConstraint(q)),
        ),
      );
    }
  }

  toNonQueryFilterConstraint = (q: QueryOperation): QueryNonFilterConstraint => {
    switch (q.type) {
      case 'orderBy': {
        const qOrderBy = q as OrderByQueryOperation;
        return orderBy(qOrderBy.field === ":documentId" ? this.documentIdFieldPath : qOrderBy.field, qOrderBy.direction);
      }
      case 'startAfter': {
        const qStartAfter = q as StartAfterQueryOperation;
        return startAfter(qStartAfter.value);
      }
      case 'limit':
      default: {
        const qLimit = q as LimitQueryOperation;
        return limit(qLimit.number);
      }
    }
  }

  toQueryFilterConstraint = (q: QueryOperation): QueryFilterConstraint => {
    switch (q.type) {
      case 'or': {
        const qOr = q as OrQueryOperation;
        const orArr = qOr.value.map(q => {
          return this.toQueryFilterConstraint(q);
        });

        return or(...orArr);
      }
      case 'and': {
        const qAnd = q as AndQueryOperation;
        const orArr = qAnd.value.map(q => {
          return this.toQueryFilterConstraint(q);
        });

        return and(...orArr);
      }
      default: {
        const qWhere = q as WhereQueryOperation;
        return where(qWhere.field === ":documentId" ? this.documentIdFieldPath : qWhere.field, qWhere.op, qWhere.value);
      }
    }
  }

  toQueryConstraint = (q: QueryOperation): QueryConstraint => {
    switch (q.type) {
      case 'where': {
        const qWhere = q as WhereQueryOperation;
        return where(qWhere.field === ":documentId" ? this.documentIdFieldPath : qWhere.field, qWhere.op, qWhere.value);
      }
      case 'orderBy': {
        const qOrderBy = q as OrderByQueryOperation;
        return orderBy(qOrderBy.field === ":documentId" ? this.documentIdFieldPath : qOrderBy.field, qOrderBy.direction);
      }
      case 'startAfter': {
        const qStartAfter = q as StartAfterQueryOperation;
        return startAfter(qStartAfter.value);
      }
      case 'limit':
      default: {
        const qLimit = q as LimitQueryOperation;
        return limit(qLimit.number);
      }
    }
  };

  getDocuments = (path: string) => getDocs(collection(this.dbService, path));
  /**
   * Writes to the document referred to by the specified docId.
   * If the document does not yet exist, it will be created.
   * If the document exists, it will be merged.
   * @param collectionPath the path to the collection
   * @param docId the id of the document
   * @param data the data to merge
   * @returns A Promise resolved once the data has been successfully written to the backend (note that it won't resolve while you're offline).
   */
  updateDocument = (collectionPath: string, docId: string, data: any) =>
    setDoc(this.getDocumentRef(collectionPath, docId), data, { merge: true });

  /**
   * Writes to the document referred to by the specified docId.
   * If the document does not yet exist, it will be created.
   * If the document exists, it will be replaced.
   * @param collectionPath the path to the collection
   * @param docId the id of the document
   * @param data the data to merge
   * @returns A Promise resolved once the data has been successfully written to the backend (note that it won't resolve while you're offline).
   */
  replaceDocument = (collectionPath: string, docId: string, data: any) =>
    setDoc(this.getDocumentRef(collectionPath, docId), data);

  /**
   * Deletes the document referred to by the specified docId.
   * @param collectionPath the path to the collection
   * @param docId the id of the document
   * @returns A Promise resolved once the data has been successfully written to the backend (note that it won't resolve while you're offline).
   */
  deleteDocument = (collectionPath: string, docId: string) =>
    deleteDoc(this.getDocumentRef(collectionPath, docId));

  deleteArrayValueFromDocument = async (collectionPath: string, docId: string, keyValue: string, value: any) => {
    try {

      const docRef = this.getDocumentRef(collectionPath, docId);

      await updateDoc(docRef, { [keyValue]: arrayRemove(value) });
    } catch (err) {
      Promise.reject(err)
    }
  }

  get currentTimestamp() {
    return Timestamp.now().toMillis();
  }

  uploadFile = async (file: File, parentPath: string, name?: string) => {
    // validate file size
    const fileSize = Math.round(file.size / 1024);
    const maxFileSize = 3072; //3mb
    if (fileSize > maxFileSize) {
      // file too large
      throw new Error('Invalid file.The file size must not be more than 3 mb');
    }

    // Create the file metadata
    var metadata: UploadMetadata = {
      contentType: file.type,
    };

    try {
      // upload file
      const uploadRef = storageRef(this.storageService, `${parentPath}/${name ?? file.name}`);
      var uploadResult = await uploadBytes(uploadRef, file, metadata);
      return getDownloadURL(uploadResult.ref);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  subscribeCollection = (collectionPath: string, callBack: (snapshot: QuerySnapshot<DocumentData>) => void) => onSnapshot(collection(this.dbService, collectionPath), callBack)

  subscribeDocument = (collectionPath: string, docId: string, callBack: (snapshot: DocumentSnapshot<DocumentData>) => void) => onSnapshot(doc(this.dbService, `${collectionPath}/${docId}`), callBack);

  deleteFileByDownloadUrl = async (url: string) => {
    try {
      const filePath = url.substring(url.lastIndexOf('/') + 1, url.indexOf('?'));

      const decodedFilePath = decodeURIComponent(filePath);

      console.log(decodedFilePath);
      // Create a reference to the file to delete
      const deleteRef = storageRef(this.storageService, decodedFilePath);

      // Delete the file
      await deleteObject(deleteRef);
    } catch (err) {
      Promise.reject(err);
    }
  };

  get documentIdFieldPath() {
    return documentId();
  }
};

export default FirebaseStore;
