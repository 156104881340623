import { differenceInMonths, format } from 'date-fns';
import {
  ConditionComparer,
  ConditionOPS,
  IEntryCondition,
  ILeaseAgreementTemplate,
  ILeaseAgreementTemplateData,
  ILeaseAgreementTemplateEntry,
  ILeaseAgreementTemplateSection,
  ILeaseAgreementTemplateHeader,
  ILeaseAgreement,
  RentDueDay,
  ILeasePolicy,
} from 'realhaus-sdk';
import { loadXml } from '../utils/xmlLoader';
import { LeaseTemplatePaths } from '../constants/lease';
import { formatAddress } from '../utils/address';
import { bedroomName } from '../utils/property';

export class LeaseAgreementStore {
  generateLeaseAgreement = async (lease: ILeaseAgreement): Promise<ILeaseAgreementTemplate> => {

    const province = lease.listingInfo.address.province.toUpperCase();
    if (!Object.keys(LeaseTemplatePaths).find(p => p === province)) {
      throw new Error(`Unable to generate lease agreement. Province "${province}" is not supported at this time`);
    }

    const leaseTemplatePath = LeaseTemplatePaths[province];

    // convert lease agreement to lease data
    const leaseData = this.leaseAgreementToLeaseData(lease);
    return await this.parseLeaseAgreementTemplate(leaseTemplatePath, leaseData);
  }

  conditionSatisfied = (condition: IEntryCondition) => {
    return ConditionComparer[condition.op](condition.left, condition.right);
  }

  parseLeaseAgreementTemplate = async (path: string, leaseData: ILeaseAgreementTemplateData): Promise<ILeaseAgreementTemplate> => {
    // load lease template
    const xmlDoc = await loadXml(path);

    if (!!xmlDoc) {
      const root = xmlDoc.children[0];
      if (root == null) return { sections: [] };
      var section = root.firstElementChild;
      const leaseSections: ILeaseAgreementTemplateSection[] = [];
      let leaseHeader: ILeaseAgreementTemplateHeader = { name: '', label: '' };
      while (section) {
        if (section.tagName.endsWith('header')) {
          // parse header
          leaseHeader = this.parseHeader(section, leaseData);
        }
        if (section.tagName.endsWith('section')) {
          // parse section
          leaseSections.push(this.parseSection(section, leaseData));
        }
        section = section.nextElementSibling;
      }

      return { sections: leaseSections, header: leaseHeader };
    }
    return { sections: [] };
  };

  parseSection = (section: Element, leaseData: ILeaseAgreementTemplateData): ILeaseAgreementTemplateSection => {
    const leaseSection: ILeaseAgreementTemplateSection = {
      title: section.getAttribute('title') ?? '',
      entries: [],
    };

    if (section.children.length > 0) {
      const entries = section.children[0];
      var entry = entries.firstElementChild;
      while (entry) {
        if (entry.tagName.endsWith('entry')) {
          // parse entries
          leaseSection.entries?.push(this.parseEntry(entry, leaseData))
        }

        entry = entry.nextElementSibling;
      }
    }

    return leaseSection;
  }

  parseHeader = (section: Element, leaseData: ILeaseAgreementTemplateData): ILeaseAgreementTemplateHeader => {
    const leaseHeader: ILeaseAgreementTemplateHeader = {
      name: section.getAttribute('name') ?? '',
      label: section.getAttribute('label') ?? '',
      entries: [],
    };

    if (section.children.length > 0) {
      const entries = section.children[0];
      var entry = entries.firstElementChild;
      while (entry) {
        if (entry.tagName.endsWith('entry')) {
          leaseHeader.entries?.push(this.parseEntry(entry, leaseData))
        }

        entry = entry.nextElementSibling;
      }
    }

    return leaseHeader;
  }

  parseEntry = (element: Element, leaseData: ILeaseAgreementTemplateData): ILeaseAgreementTemplateEntry => {
    var value = element.getAttribute('value') ?? element.textContent ?? '';

    // update lease values
    if (!!value) {
      Object.entries(leaseData).forEach((entry) => {
        value = value.replaceAll(`{{${entry[0]}}}`, entry[1])
      });
    }

    const leaseEntry: ILeaseAgreementTemplateEntry = {
      value,
      condition: this.parseEntryCondition(element, leaseData),
      entries: []
    };

    // recursively parse sub entries
    if (element.childElementCount > 0 && element.firstElementChild?.tagName.endsWith('entries')) {
      const subEntries = element.children[0];
      var subEntry = subEntries.firstElementChild;
      while (subEntry) {
        if (subEntry.tagName.endsWith('entry')) {
          // parse entries
          leaseEntry.entries?.push(this.parseEntry(subEntry, leaseData))
        }

        subEntry = subEntry.nextElementSibling;
      }
    }

    return leaseEntry;
  }

  parseEntryCondition = (element: Element, leaseData: ILeaseAgreementTemplateData) => {
    // parse conditions
    const elementCondition = element.getAttribute('condition') ?? '';
    if (elementCondition !== '') {
      const operation = Object.keys(ConditionComparer).find(op => elementCondition.indexOf(op) >= 0);
      if (!!operation) {
        const c = elementCondition.split(operation);
        const entryCondition: IEntryCondition = {
          op: operation as ConditionOPS,
          left: c[0],
          right: c[1]
        };

        Object.entries(leaseData).forEach((entry) => {
          entryCondition.left = entryCondition.left.replaceAll(`{{${entry[0]}}}`, entry[1])
          entryCondition.right = entryCondition.right.replaceAll(`{{${entry[0]}}}`, entry[1])
        });

        return entryCondition;
      }
    }
  }

  leaseAgreementToLeaseData = (lease: ILeaseAgreement): ILeaseAgreementTemplateData => {
    // DATES
    const start = new Date(lease.moveinDate);
    const end = new Date(lease.moveoutDate);

    let dueDay = 'FIRST (1st)';

    switch (lease.dueRentDay) {
      case RentDueDay.END_OF_MONTH: {
        dueDay = 'LAST';
        break;
      }
      case RentDueDay.FIRST_OF_MONTH: {
        dueDay = 'FIRST (1st)';
        break;
      }
      default: {
        dueDay = `${lease.dueRentDay}`;
        if (dueDay.endsWith('1')) {
          dueDay += dueDay === '11' ? 'th' : 'st';
        } else if (dueDay.endsWith('2')) {
          dueDay += 'nd';
        } else if (dueDay.endsWith('3')) {
          dueDay += 'rd';
        } else {
          dueDay += 'th';
        }
      }
    }

    const leaseTermMonths = differenceInMonths(end, start);
    const leaseData: ILeaseAgreementTemplateData = {
      LENGTH_OF_LEASE_MONTHS: leaseTermMonths,
      START_DATE_OF_LEASE: format(start, "PPP"),
      END_DATE_OF_LEASE: format(end, "PPP"),
      PETS: lease.policies?.petsAllowed,
      PARKING: !!lease.listingInfo?.amenities?.find(a => a.key === 'secured_garage') || !!lease.fees?.parkingFee,
      SMOKING: lease.policies?.smokingAllowed,
      RENT_AMOUNT: lease.rentAmount,
      RENT_DUE_DAY: dueDay,
      NSF_FEE_AMOUNT: lease.fees?.nsfFee ?? 0,
      LATE_RENT_FEE_AMOUNT: lease.fees?.lateRentFee,
      LATE_RENT_GRACE_PERIOD_DAYS: 15,
      SECURITY_DEPOSIT_AMOUNT: lease.securityDepositAmount,
      SECURITY_DEPOSIT_RETURN_IN_DAYS: 10,
      LEASE_RENEW_DAYS: 60,
      MEDIATION_TIMELINE_BEFORE_ESCALATION_DAYS: 14,
      PARKING_FEE_AMOUNT: lease.fees?.parkingFee ?? 0,
      RENT_DEPOSIT_RETURN_IN_DAYS: 30,
      INSURANCE: !!lease.policies.rentInsuranceProof,
      KEY_DEPOSIT_AMOUNT: 0,
      LEASE_END_ACTION: lease.leaseEndAction,
      PET_FEE_AMOUNT: lease.fees?.petFee ?? 0,
      SMOKING_FEE_AMOUNT: lease.fees?.smokingFee ?? 0,
      PROPERTY_TYPE: lease.listingInfo.propertyType,
      SERVICES_UTILITIES_INCLUDED: utilitiesCoveredString(lease.policies),
      PROPERTY_ADDRESS: formatAddress(lease.listingInfo.address),
      PROPERTY_DESCRIPTION: propertySummary(lease),
    };
    return leaseData;
  };
}

const parkingSummary = (lease: ILeaseAgreement) => {
  const listing = lease.listingInfo;
  let summary = '';
  if (
    !!listing?.amenities?.find((a) => a.key === 'secured_garage') &&
    (!lease.fees?.parkingFee || lease.fees?.parkingFee === 0)
  ) {
    summary = 'Garage Parking';
  }

  if (!!listing?.amenities?.find(a => a.key === 'guest_parking')) {
    summary += ' and Guest Parking';
  }

  return summary;
}

const propertySummary = (lease: ILeaseAgreement) => {
  const listing = lease.listingInfo;
  let summary = '';

  if (lease.listingInfo.isSharedProperty) {
    summary = `1 ${bedroomName(listing.bedroomForRent)} bedroom, 1 (${listing.bathroomPrivacy}) bathroom in a shared property`;
  } else {
    summary = `This is a ${listing.bedrooms} bedrooms, ${listing.bathrooms} bathrooms property`;
  }
  if (parkingSummary(lease) !== '') {
    summary += ` with ${parkingSummary(lease)}`;
  }

  return summary;
}

const utilitiesCoveredString = (policy: ILeasePolicy) => {
  let uStr = "";
  policy.utilitiesCovered.forEach((u, i) => {
    if (i < policy.utilitiesCovered.length - 1) {
      uStr += `${u.utility}, `;
    } else {
      uStr += `${u.utility}`;
    }
  });

  return uStr;
}