import { IsValidNumber } from '../global/util';

const join = (strings: string[], joinChar: string) => {
  return strings.filter(s => s.length > 0).join(joinChar);
};

const trim = (text: string): string => {
  return `${text}`.replace(/\s/g, "");
};

const toTitleCase = (text: string): string => {
  return text.replace(
    /\w\S*/g,
    function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
};

const asCurrency = (price: number) => {
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'CAD',
  });
  if (!IsValidNumber(price)) price = 0;
  return formatter.format(price);
};

const asPercent = (value: number): string => {
  if (!IsValidNumber(value)) return ''
  return `${Number(value).toFixed(0)} %`;
}


/**
 * Normalize the income based on the income frequency:
 *  if monthly, return income as is
 *  if yearly, divide income by 12, then return the value
 *  if bi-weekly, multiply income by 26, then divide by 12.
 *  if weekly, multiply income by 52 weeks then divide by 12.
 * @param income
 * @param incomeType
 * @returns normalized income
 */
const normalizeIncome = (income: number | undefined, incomeType: string | undefined) => {
  if (!income || !incomeType) {
    return 0;
  }

  switch (incomeType) {
    case 'monthly':
      return income;
    case 'bi-weekly':
      return (income * 26) / 12;
    case 'weekly':
      return (income * 52) / 12;
    default:
      return income / 12;
  }
}

const normalizeIncomeType = (incomeType: string) => {
  switch (incomeType) {
    case 'monthly':
      return 'mo';
    case 'bi-weekly':
      return 'bi-wkly';
    case 'weekly':
      return 'wkly';
    default:
      return 'yr';
  }
}

const printIncome = (income: number, incomeType: string) => {
  return `${asCurrency(income)} / ${normalizeIncomeType(incomeType)}`
}

const toQueryString = (obj: any): string => {
  return Object.keys(obj)
    .filter(key => {
      return (typeof obj[key] === 'string' && obj[key] !== '');
    })
    .map(key => {
      return `${key}=${obj[key]}`;
    }).join('&');
}

const truncateString = (str: string, index = 55) => {
  if (str.length > index) return `${str.substring(0, index)}...`

  return str
}

const getDateTimeFormat = (date: number): string => {
  const day = new Date(date).getDate();
  const month = new Date(date);
  const monthName = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(month);
  const year = new Date(date).getFullYear();
  return `${monthName} ${day}, ${year}`;
};

const getMonthDay = (date: number): string => {
  const day = new Date(date).getDate();
  const month = new Date(date);
  const monthName = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(month);
  return `${monthName} ${day}`;
};

const getMonthAndYear = (time: number) => {
  const date = new Date(time);
  const monthName = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date);
  const year = date.getFullYear();
  return `${monthName} ${year}`;
};

const enumToDisplayString = (grade: string) => {
  return grade.replace('_', ' ');
}

const StringUtils = {
  join,
  trim,
  toTitleCase,
  asCurrency,
  toQueryString,
  normalizeIncome,
  printIncome,
  truncateString,
  getDateTimeFormat,
  getMonthAndYear,
  enumToDisplayString,
  asPercent,
  getMonthDay,
};

export default StringUtils;