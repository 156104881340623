import { makeAutoObservable, observable } from 'mobx';
import { Collections, WhereQueryOperation } from './dbStore';
import FirebaseStore from './firebaseStore';
import { Grades, GradeType, IdLeaseAgreement, IdRating, ILeaseRatingReviewDetails, ILeaseRatingsReview, IRating, IRatingReviewTerm, IRatings, IReview, SubjectType } from 'realhaus-sdk';
import { format } from 'date-fns';
import { TenantStore } from './tenantStore';
import { UserStore } from './userStore';

export class RatingStore {
  MAX_RATING = 5
  @observable PendingReviews: ILeaseRatingReviewDetails[] = [];

  constructor(private fireStore: FirebaseStore, private tenantStore: TenantStore, private userStore: UserStore) {
    makeAutoObservable(this);
  }

  createRating = async (rating: IRating): Promise<string | undefined> => {
    const uid = await this.fireStore.authService.currentUser?.uid;
    if (!uid) return;
    var added = await this.fireStore.addDocument(Collections.ratingsAndReviews, rating);

    return Promise.resolve(added.id);
  };

  getRating = async (ratingId: string): Promise<IdRating> => {
    const rating = (await this.fireStore.getDocument(Collections.ratingsAndReviews, ratingId)).data() as IRating;
    return {
      id: ratingId,
      ...rating
    }
  };

  getPendingReviews = async (isLandlord: boolean): Promise<ILeaseRatingReviewDetails[]> => {
    const leaseIdsQuery = [
      isLandlord ? new WhereQueryOperation('ownerId', '==', this.userStore.userId) :
        new WhereQueryOperation('tenantIds', 'array-contains', this.userStore.userId)
    ];
    // get leaseIds where this user is a lanndlord or tenant
    const leaseIds: string[] = (await this.fireStore.findDocuments(Collections.leaseAgreements, leaseIdsQuery)).docs.map((doc) => doc.id)

    const reviewsQuery: WhereQueryOperation[] = [];
    if (leaseIds.length > 0) {
      reviewsQuery.push(new WhereQueryOperation(":documentId", 'in', leaseIds));
    }

    reviewsQuery.push(isLandlord ? new WhereQueryOperation('ownerId', '==', this.userStore.userId) :
      new WhereQueryOperation('tenantId', '==', this.userStore.userId))

    const userReviews = (await this.fireStore.findDocuments(Collections.ratingsAndReviews, reviewsQuery)).docs.map((doc) => doc.data() as ILeaseRatingReviewDetails);

    this.PendingReviews = userReviews.filter((review) => this.isPendingReview(isLandlord, review));
    return { ...this.PendingReviews };
  }

  isPendingReview = (userIsLandlord: boolean, review: ILeaseRatingReviewDetails) => {
    const midterm = review.midterm;
    const endterm = review.endterm;

    const midTermDue = !!midterm && midterm.dateDue >= Date.now();
    const endTermDue = !!endterm && endterm.dateDue >= Date.now();

    if (userIsLandlord) {
      return midTermDue && !midterm.ownerCompletedOn || endTermDue && !endterm.ownerCompletedOn;
    } else {
      return midTermDue && !midterm.tenantCompletedOn || endTermDue && !endterm.tenantCompletedOn;
    }
  }

  updateRatingDetails = async (leaseAgreementId: string, ratingDetail: { [key in IRatingReviewTerm as string]: any }, term: string): Promise<void> => {
    if (!ratingDetail || !leaseAgreementId) return;
    await this.fireStore.updateDocument(`${Collections.ratingsAndReviews}`, `${leaseAgreementId}`, { [term]: ratingDetail });

  }

  getTenantReviewsbyId = async (tenantId: string): Promise<({ id: string, leaseAgreementId: string } & ILeaseRatingsReview)[] | void> => {
    if (!tenantId) return;
    const leaseIdsWithTenant = (await this.fireStore.findDocuments(`${Collections.ratingsAndReviews}`, [new WhereQueryOperation('tenantId', '==', tenantId)])).docs.map((data) => data.id);
    // array of reviews
    const reviewsArray: ({ id: string, leaseAgreementId: string } & ILeaseRatingsReview)[] = []

    if (leaseIdsWithTenant.length > 0) {

      for (const leaseId of leaseIdsWithTenant) {
        const review = (await this.fireStore.findDocuments(`${Collections.ratingsAndReviews}/${leaseId}/${Collections.reviews}`, [new WhereQueryOperation(":documentId", '!=', tenantId)])).docs.map((rat) => ({ id: rat.id, leaseAgreementId: leaseId, ...rat.data() as ILeaseRatingsReview }))

        if (review.length > 0) {
          reviewsArray.push(review[0])
        }
      }
    }
    return reviewsArray;
  }

  getLeaseAgreementRatingsReview = async (leaseAgreement: IdLeaseAgreement, userId: string): Promise<ILeaseRatingsReview | undefined> => {
    if (!leaseAgreement) return;
    return (await this.fireStore.getDocument(`${Collections.ratingsAndReviews}/${leaseAgreement.id}/${Collections.reviews}`, userId)).data() as ILeaseRatingsReview
  }

  upsertReview = async (leaseAgreementId: string, userId: string, leaseRatingReview: ILeaseRatingsReview): Promise<void> => {
    if (!userId || !leaseAgreementId) return;
    const path = `${Collections.ratingsAndReviews}/${leaseAgreementId}/${Collections.reviews}`;
    await this.fireStore.updateDocument(path, userId, leaseRatingReview);
  }

  updateRating = async (ratingId: string, newRating: IRating): Promise<void> => {
    return await this.fireStore.updateDocument(Collections.ratingsAndReviews, ratingId, newRating);
  };

  deleteRating = async (ratingId: string): Promise<void> => {
    await this.fireStore.deleteDocument(Collections.ratingsAndReviews, ratingId);
  };

  getRatings = async (subjectId: string, subjectType: SubjectType): Promise<IRating[]> => {
    const query = [
      new WhereQueryOperation('subjectId', '==', subjectId),
      new WhereQueryOperation('subjectType', '==', subjectType)
    ];
    return (await this.fireStore.findDocuments(Collections.ratingsAndReviews, query))
      .docs.map(doc => ({ id: doc.id, ...doc.data() as IRating } as IdRating));
  };

  getListingRatings = async (listingId: string): Promise<IRating[]> => {
    const leaseQuery = [new WhereQueryOperation('listingId', '==', listingId)];
    const leaseIds = (await this.fireStore.findDocuments(Collections.leaseAgreements, leaseQuery)).docs.map(doc => doc.id);

    return (await Promise.all(leaseIds.map(async (leaseId) => {
      const query = [
        new WhereQueryOperation('subjectId', '==', leaseId),
        new WhereQueryOperation('subjectType', '==', SubjectType.LEASE)
      ];
      return (await this.fireStore.findDocuments(Collections.ratingsAndReviews, query))
        .docs.map(doc => ({ id: doc.id, ...doc.data() as IRating } as IdRating));
    }))).flat();
  };

  calculateRating = async (ratings: IRating[]): Promise<IRatings | undefined> => {
    if (!ratings || ratings.length === 0) return;

    const gradesArray = ratings.map(({ grades }) => grades);

    const reviews = await Promise.all(ratings.map(async ({ review, reviewerId, timestamp }) => {
      const userBio = await this.tenantStore.getUserBio(reviewerId);
      return {
        firstName: userBio.firstname,
        profileImage: userBio.photoURL,
        review,
        date: format(timestamp, 'MMMM yyyy'),
      } as IReview
    }));

    const totalGrades = gradesArray.reduce((grades1, grades2) => {
      let gradesSum = {} as Grades;
      for (const gradeName in grades1) {
        const gradeType = gradeName as GradeType;
        gradesSum[gradeType] = (grades1[gradeType] || 0) + (grades2[gradeType] || 0);
      }
      return gradesSum;
    });

    let averageGrades = {} as Grades;
    for (const gradeName in totalGrades) {
      const gradeType = gradeName as GradeType;
      averageGrades[gradeType] = totalGrades[gradeType] / gradesArray.length;
    }

    const calculatedRating = Object.values(averageGrades).reduce((n1, n2) => n1 + n2) / Object.values(averageGrades).length;

    return {
      rating: calculatedRating,
      reviews,
      averageGrades,
      maxRating: this.MAX_RATING
    };
  }

  calculateTenantRating = async (subjectId: string): Promise<IRatings | undefined> => {
    const ratings = await this.getRatings(subjectId, SubjectType.TENANT);
    return await this.calculateRating(ratings);
  }

  calculateListingRating = async (listingId: string): Promise<IRatings | undefined> => {
    const ratings = await this.getListingRatings(listingId);
    return await this.calculateRating(ratings);
  }
}