import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControlLabel,
    Grid,
    Paper,
    Radio,
    RadioGroup,
    Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react';
import { useContext, useState } from 'react';
import { StripePaymentLink } from '../../appConfig';
import { RootStoreContext } from '../../global/storeContext';
import { InputFieldMoney } from '../formComponents/inputfieldmoney';
import {
    LargeInteracIcon,
    SmallInteracIcon,
    SmallEFTIcon,
    SmallCreditCardIcon,
} from '../icon/vendorIcons';
import { RightDrawer } from '../uiComponents/rightDrawer';
import { NumericFormat } from 'react-number-format';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FinixPaymentForm } from './finix';

export enum PaymentMethod {
    INTERAC = 'INTERAC',
    EFT = 'EFT',
    CREDITCARD = 'CREDITCARD',
}
/**
 * Calculate the net amount inclusive of the fee to deduct from the user for transferring a desired amount
 * calculating from the point of view that the vendor will charge Realhaus based on the net amount provided.
 * As a result this amount is charged to the user and is inclusive of that the fee the vendor will charge to Realhaus.
 * e.g. (amount + $0.65) + (1 - 2.9%)
 *
 * @param desiredAmount the desired amount to transfer
 * @param percentageFee the percentage fee the vendor will charge
 * @param fixedFee the fixed fee the vendor will charge
 * @returns amount to charge the user that will be inclusive of desired amount and vendor fee
 */
const calculateNetAmountInclusively = (
    desiredAmount: number,
    percentageFee: number,
    fixedFee: number,
) => {
    return desiredAmount === 0
        ? 0
        : (Number(desiredAmount) + Number(fixedFee)) / (1 - Number(percentageFee));
};

const getTrxFeeBreakdown = (amount: number, transactionFees: any) => {
    const interacFeeAmount = transactionFees?.INTERAC
        ? calculateNetAmountInclusively(
              amount,
              transactionFees?.INTERAC?.PERCENT,
              transactionFees?.INTERAC?.AMOUNT,
          )
        : amount;
    const ccFeeAmount = transactionFees?.CREDITCARD
        ? calculateNetAmountInclusively(
              amount,
              transactionFees?.CREDITCARD?.PERCENT,
              transactionFees?.CREDITCARD?.AMOUNT,
          )
        : amount;
    const eftFeeAmount = transactionFees?.EFT
        ? calculateNetAmountInclusively(
              amount,
              transactionFees?.EFT?.PERCENT,
              transactionFees?.EFT?.AMOUNT,
          )
        : amount;

    return {
        [PaymentMethod.CREDITCARD]: {
            fee: Number((ccFeeAmount - amount).toFixed(2)),
            netAmount: Number(ccFeeAmount.toFixed(2)),
        },
        [PaymentMethod.EFT]: {
            fee: Number((eftFeeAmount - amount).toFixed(2)),
            netAmount: Number(eftFeeAmount.toFixed(2)),
        },
        [PaymentMethod.INTERAC]: {
            fee: Number((interacFeeAmount - amount).toFixed(2)),
            netAmount: Number(interacFeeAmount.toFixed(2)),
        },
    };
};

export const BillPaymentCheckout: React.FC<{
    leaseId: string;
    billId: string;
    open: boolean;
    handleClose: () => void;
    handleComplete: () => void;
    handleFailure: () => void;
}> = observer(({ billId, leaseId, open, handleClose, handleComplete, handleFailure }) => {
    const { uiStore, userStore, leaseStore, walletStore, analyticsStore } =
        useContext(RootStoreContext);
    const [amount, setAmount] = useState(0);
    const [openInteracConfirmModal, setOpenInteracConfirmModal] = useState(false);
    const [openFinixTokenModal, setOpenFinixTokenModal] = useState(false);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod | undefined>();
    const [feeBreakdown, setFeeBreakdown] =
        useState<{ [key in PaymentMethod]: { fee: number; netAmount: number } }>();
    const [paramountInteracPayload, setParamountInteracPayload] = useState<{
        details: string;
        merchantId: string;
        iv: string;
        paymentUrl: string;
    }>();
    const { paymentVendorsList, transactionFees } = useFlags();
    const formRef = useRef<HTMLFormElement>(null);

    useEffect(() => {
        if (!billId || !leaseId) {
            // clear fields
            setAmount(0);
            setSelectedPaymentMethod(undefined);
            setFeeBreakdown(undefined);
            console.log(`-- Empty Bill ID`);
            return;
        }

        uiStore.showLoading();
        leaseStore
            .getLeaseBill(leaseId, billId)
            .then((bill) => {
                if (!bill || !bill.status || !bill.amount) {
                    return;
                }

                if (isNaN(bill.amount) || bill.amount < 0) return;
                console.log(`--Bill Amount = ${bill.amount}`);
                setAmount(Number(bill.amount));
                const vendorFeeBreakdown = getTrxFeeBreakdown(Number(bill.amount), transactionFees);
                setFeeBreakdown({ ...vendorFeeBreakdown });
            })
            .catch(() => {})
            .finally(() => {
                uiStore.hideLoading();
            });
    }, [billId]);

    const netAmount =
        (feeBreakdown && selectedPaymentMethod && feeBreakdown[selectedPaymentMethod].netAmount) ??
        0;

    const paymentOptionEFTAvailable = paymentVendorsList[`${PaymentMethod.EFT}`] !== undefined;
    const paymentOptionInteracAvailable =
        paymentVendorsList[`${PaymentMethod.INTERAC}`] !== undefined;
    const paymentOptionCCAvailable =
        paymentVendorsList[`${PaymentMethod.CREDITCARD}`] !== undefined;

    const handleVendorChange = (_: React.ChangeEvent<HTMLInputElement>, value: string) => {
        if (PaymentMethod.INTERAC === value) {
            setSelectedPaymentMethod(PaymentMethod.INTERAC);
        } else if (PaymentMethod.CREDITCARD === value) {
            setSelectedPaymentMethod(PaymentMethod.CREDITCARD);
        } else if (PaymentMethod.EFT === value) {
            setSelectedPaymentMethod(PaymentMethod.EFT);
        } else {
            setSelectedPaymentMethod(undefined);
        }
    };

    const handleCloseCheckout = (paymentComplete: boolean) => {
        if (paymentComplete) {
            handleComplete();
        } else {
            handleClose();
        }

        setFeeBreakdown(undefined);
        setSelectedPaymentMethod(PaymentMethod.INTERAC);
    };

    const handleCCPayment = () => {
        switch (paymentVendorsList['CREDITCARD']) {
            case 'STRIPE': {
                window.open(
                    `${StripePaymentLink}?client_reference_id=${userStore.userInfo?.uid}&prefilled_email=${userStore.userInfo?.email}`,
                    '_blank',
                );
                break;
            }
            case 'FINIX': {
                setOpenFinixTokenModal(true);
            }
        }
    };

    const handleCloseInterac = () => {};
    const handleCloseFinix = (paymentCompleted: boolean) => {
        setOpenFinixTokenModal(false);
        handleCloseCheckout(paymentCompleted);
    };

    const handlePayBill = async () => {
        if (!feeBreakdown || !selectedPaymentMethod || amount < 0) {
            return;
        }

        try {
            uiStore.showLoading();

            const { fee, netAmount } = feeBreakdown[selectedPaymentMethod];
            console.debug(
                `Charging fee of ${fee} for amount ${amount} for a net amount of ${netAmount}`,
            );

            // create transaction log for payment
            const initiatePayment = () =>
                walletStore.initiateBillPayment(
                    netAmount,
                    fee,
                    leaseId,
                    billId,
                    `${selectedPaymentMethod}`,
                );

            switch (selectedPaymentMethod) {
                case PaymentMethod.CREDITCARD:
                    // ToDo: Integrate CC Payment Vendor
                    handleCCPayment();
                    break;
                case PaymentMethod.EFT:
                    // ToDo: Integrate EFT Payment Vendor
                    break;
                case PaymentMethod.INTERAC: {
                    setOpenInteracConfirmModal(true);
                    const res = await initiatePayment();

                    if (res?.paramountInteracPayload) {
                        // make paramount commerce interac payment request
                        const pData = res.paramountInteracPayload;
                        console.log(pData);
                        setParamountInteracPayload({
                            ...pData,
                        });

                        setTimeout(() => {
                            if (formRef.current) {
                                formRef.current.submit(); // Use the ref to submit the form
                            }
                            setOpenInteracConfirmModal(false);
                            handleCloseCheckout(true);
                        }, 1000);
                    } else if (res?.interacUrl) {
                        analyticsStore.trackBillPayment(netAmount, `${PaymentMethod.INTERAC}`);
                        window.open(res.interacUrl, '_blank');
                        setOpenInteracConfirmModal(false);
                        handleCloseCheckout(true);
                    } else {
                        const msg =
                            'We were unable to pay your bill through interac at this time. Please try again later';
                        const err = res?.errorMsg ? `${res.errorMsg}\n${msg}` : msg;
                        uiStore.error(err);
                        console.error(err);
                        setOpenInteracConfirmModal(false);
                    }

                    break;
                }
            }
        } catch (err) {
        } finally {
            uiStore.hideLoading();
        }
    };

    return (
        <>
            <RightDrawer isOpen={open} onOpen={() => {}} onClose={() => handleCloseCheckout(false)}>
                <div style={{ height: 'calc(100vh - 140px)' }}>
                    <Grid container spacing={3}>
                        <Grid item xs={1}></Grid>
                        <Grid container item xs={10} spacing={3}>
                            <Grid item>
                                <Typography variant='h5'>Pay your bill</Typography>
                            </Grid>
                            <Grid container item spacing={4}>
                                <Grid item xs={12}>
                                    <Typography variant='body1' gutterBottom>
                                        Amount
                                    </Typography>
                                    <InputFieldMoney
                                        id='amount'
                                        value={amount}
                                        placeholder='Amount to Fund'
                                        style={{ marginTop: '0', paddingTop: '0' }}
                                        fullWidth
                                        disabled
                                        readOnly
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='body1' gutterBottom>
                                        Select Payment Method
                                    </Typography>
                                    <RadioGroup
                                        aria-label='fee-options'
                                        name='fee-options'
                                        value={selectedPaymentMethod}
                                        onChange={handleVendorChange}
                                    >
                                        {!!paymentOptionInteracAvailable && (
                                            <Paper
                                                elevation={0}
                                                variant='outlined'
                                                style={{
                                                    padding: '0.5em',
                                                    paddingLeft: '1em',
                                                }}
                                            >
                                                <FormControlLabel
                                                    value={PaymentMethod.INTERAC}
                                                    control={
                                                        <Grid style={{ paddingRight: '1em' }}>
                                                            <Radio value={PaymentMethod.INTERAC} />
                                                            <SmallInteracIcon />
                                                        </Grid>
                                                    }
                                                    label={
                                                        <Typography variant='body1'>
                                                            INTERAC&reg;
                                                        </Typography>
                                                    }
                                                />

                                                <Grid
                                                    container
                                                    alignContent='center'
                                                    style={{ padding: '8px 34px' }}
                                                >
                                                    <Typography
                                                        variant='body2'
                                                        style={{ width: '100%' }}
                                                    >
                                                        INTERAC is a registered trade-mark and the
                                                        INTERAC logo is a trade-mark of Interac
                                                        Corp. Used under license.
                                                    </Typography>
                                                </Grid>
                                            </Paper>
                                        )}
                                        {!!paymentOptionCCAvailable && (
                                            <Paper
                                                elevation={0}
                                                variant='outlined'
                                                style={{
                                                    padding: '0.5em',
                                                    paddingLeft: '1em',
                                                }}
                                            >
                                                <FormControlLabel
                                                    value={PaymentMethod.CREDITCARD}
                                                    control={
                                                        <Grid style={{ paddingRight: '1em' }}>
                                                            <Radio
                                                                value={PaymentMethod.CREDITCARD}
                                                            />
                                                            <SmallCreditCardIcon />
                                                        </Grid>
                                                    }
                                                    label='Credit Card (VISA, MASTERCARD, AMEX)'
                                                />
                                            </Paper>
                                        )}
                                        {!!paymentOptionEFTAvailable && (
                                            <Paper
                                                elevation={0}
                                                variant='outlined'
                                                style={{
                                                    padding: '0.5em',
                                                    paddingLeft: '1em',
                                                }}
                                            >
                                                <FormControlLabel
                                                    value={PaymentMethod.EFT}
                                                    control={
                                                        <Grid style={{ paddingRight: '1em' }}>
                                                            <Radio value={PaymentMethod.EFT} />
                                                            <SmallEFTIcon />
                                                        </Grid>
                                                    }
                                                    label='EFT (Bank Transfer)'
                                                />
                                            </Paper>
                                        )}
                                    </RadioGroup>
                                    {!paymentOptionCCAvailable &&
                                        !paymentOptionEFTAvailable &&
                                        !paymentOptionInteracAvailable && (
                                            <Paper
                                                elevation={0}
                                                variant='outlined'
                                                style={{
                                                    padding: '0.5em',
                                                    paddingLeft: '1em',
                                                }}
                                            >
                                                <Typography
                                                    variant='body1'
                                                    align='center'
                                                    gutterBottom
                                                >
                                                    <b>No payment method available at this time.</b>
                                                </Typography>
                                            </Paper>
                                        )}
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={4}></Grid>
                                        <Grid item container xs={8}>
                                            <Grid container item spacing={2}>
                                                <Grid item container xs={12}>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            variant='body1'
                                                            gutterBottom
                                                            align='left'
                                                        >
                                                            Amount
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            variant='body1'
                                                            gutterBottom
                                                            align='right'
                                                        >
                                                            <NumericFormat
                                                                value={amount}
                                                                prefix='$ '
                                                                suffix=' CAD'
                                                                displayType={'text'}
                                                                thousandSeparator={true}
                                                                decimalSeparator='.'
                                                                fixedDecimalScale
                                                                decimalScale={2}
                                                            />
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item container xs={12}>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            variant='body1'
                                                            gutterBottom
                                                            align='left'
                                                        >
                                                            Transaction Fee
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            variant='body1'
                                                            gutterBottom
                                                            align='right'
                                                        >
                                                            <NumericFormat
                                                                value={
                                                                    feeBreakdown &&
                                                                    selectedPaymentMethod
                                                                        ? feeBreakdown[
                                                                              selectedPaymentMethod
                                                                          ].fee
                                                                        : 0
                                                                }
                                                                prefix='$ '
                                                                suffix=' CAD'
                                                                displayType={'text'}
                                                                thousandSeparator={true}
                                                                decimalSeparator='.'
                                                                fixedDecimalScale
                                                                decimalScale={2}
                                                            />
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Divider
                                                    variant='fullWidth'
                                                    style={{ width: '100%' }}
                                                />
                                                <Grid item container xs={12}>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            variant='body1'
                                                            gutterBottom
                                                            align='left'
                                                        >
                                                            <b>Total</b>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            variant='body1'
                                                            gutterBottom
                                                            align='right'
                                                        >
                                                            <b>
                                                                <NumericFormat
                                                                    value={netAmount}
                                                                    prefix='$ '
                                                                    suffix=' CAD'
                                                                    displayType={'text'}
                                                                    thousandSeparator={true}
                                                                    decimalSeparator='.'
                                                                    fixedDecimalScale
                                                                    decimalScale={2}
                                                                />
                                                            </b>
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button
                                                        fullWidth
                                                        onClick={handlePayBill}
                                                        color='primary'
                                                        variant='contained'
                                                        style={{ marginRight: '1rem' }}
                                                        disabled={
                                                            !(amount > 0 && !!selectedPaymentMethod)
                                                        }
                                                    >
                                                        Pay
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                </div>
                {/* Footer fine print */}
                <Grid container spacing={3}>
                    <Paper
                        role='dialog'
                        aria-modal='false'
                        aria-label='Cookie banner'
                        square
                        variant='outlined'
                        style={{
                            borderTopWidth: '1px',
                            borderWidth: '0',
                            width: '100%',
                        }}
                    >
                        <Grid
                            container
                            alignContent='center'
                            style={{ padding: '8px 34px' }}
                        ></Grid>
                    </Paper>
                </Grid>
            </RightDrawer>

            {/* Open Interac Notification Dialog */}
            <Dialog
                open={openInteracConfirmModal}
                onClose={handleCloseInterac}
                maxWidth='sm'
                fullWidth
            >
                <DialogContent>
                    <div
                        style={{
                            minHeight: '250px',
                            paddingTop: '10px',
                            padding: '10px',
                        }}
                    >
                        <Grid container>
                            <Grid item style={{ width: '100%' }}>
                                <div style={{ float: 'right', marginBottom: '15px' }}>
                                    <LargeInteracIcon />
                                </div>
                            </Grid>
                            <Grid item>
                                <div>
                                    <Typography variant='h5' align='center' gutterBottom>
                                        Creating <i>Interac</i> e-Transfer® payment request for the
                                        amount{' '}
                                        <b>
                                            <NumericFormat
                                                value={netAmount}
                                                prefix='$ '
                                                suffix=' CAD'
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                decimalSeparator='.'
                                                fixedDecimalScale
                                                decimalScale={2}
                                            />
                                        </b>
                                        .
                                    </Typography>
                                    <br />
                                    <Typography variant='h5' align='center' gutterBottom>
                                        You will be redirected shortly. Please Wait...
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Paper
                        role='dialog'
                        aria-modal='false'
                        aria-label='Cookie banner'
                        square
                        variant='outlined'
                        style={{
                            borderTopWidth: '1px',
                            borderWidth: '0',
                        }}
                    >
                        <Grid container style={{ padding: '8px 34px' }}>
                            <Typography variant='body2'>
                                INTERAC is a registered trade-mark and the INTERAC logo is a
                                trade-mark of Interac Corp. Used under license.
                            </Typography>
                        </Grid>
                    </Paper>
                </DialogActions>
            </Dialog>

            <ParamountPaymentForm
                formRef={formRef}
                url={paramountInteracPayload?.paymentUrl!}
                mId={paramountInteracPayload?.merchantId!}
                dets={paramountInteracPayload?.details!}
                ivKey={paramountInteracPayload?.iv!}
            />

            {/* Open Finix Tokenization Form */}
            <FinixPaymentForm
                openFinixTokenModal={openFinixTokenModal}
                onClose={handleCloseFinix}
                leaseId={leaseId}
                billId={billId}
                feeBreakdown={feeBreakdown}
                selectedPaymentMethod={selectedPaymentMethod}
            />
        </>
    );
});

const ParamountPaymentForm: React.FC<{
    formRef: any;
    url: string;
    mId: string;
    dets: string;
    ivKey: string;
}> = ({ formRef, url, mId, dets, ivKey }) => {
    const [formUrl, setFormUrl] = useState<string>('');
    const [merchantId, setMerchantId] = useState<string>('');
    const [detail, setDetail] = useState<string>('');
    const [iv, setIv] = useState<string>('');

    useEffect(() => {
        setFormUrl(url);
        setMerchantId(mId);
        setDetail(dets);
        setIv(ivKey);

        console.log('>>> ParamountPaymentForm props updated');
    }, [formRef, url, mId, dets, ivKey]);

    return (
        <form
            ref={formRef}
            method='POST'
            action={formUrl}
            target='_blank'
            id='paramount_interac_payment_form'
            style={{ display: 'none' }}
        >
            <input type='hidden' name='merchant_id' value={merchantId} />
            <input type='hidden' name='details' value={detail} />
            <input type='hidden' name='iv' value={iv} />
            <input type='submit' value='Interac_Payin' />
        </form>
    );
};
