import {
    Dialog,
    DialogContent,
    Grid,
    Typography,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    InputLabel,
    TextField,
    MenuItem,
    DialogActions,
    Button,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { IdPropertyListing } from 'realhaus-sdk';
import { RootStoreContext } from '../../global/storeContext';
import { paths } from '../../routes';
import { useStyles } from './leaseStyles';

export const AddTenantLeaseInvite: React.FC<{
    modalOpen: boolean;
    closeModal: () => void;
}> = observer(({ modalOpen, closeModal }) => {
    const { uiStore, leaseStore, listingStore } = useContext(RootStoreContext);
    const classes = useStyles();
    const [newListingType, setNewListingType] = React.useState('new');
    const [selectedListingId, setSelectedListingId] = React.useState<string | undefined>(undefined);
    const [listings, setListings] = useState<IdPropertyListing[]>([]);

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        if (!event.target.value || event.target.value === '') return;
        setSelectedListingId(event.target.value as string);
    };

    useEffect(() => {
        listingStore.getUserListings().then((t) => {
            if (t) setListings(t);
        });
    }, []);

    const isCloneProperty = () => newListingType === 'clone';

    const handleAddLeaseClick = async () => {
        if (newListingType === 'new') {
            uiStore.goTo(paths.landlord.newListing);
            return;
        }

        if (!selectedListingId || selectedListingId === '') {
            uiStore.error('Select a property to continue');
            return;
        }

        // Create a draft lease agreement for a non-realhaus lease
        uiStore.showLoading();
        try {
            const leaseId = await leaseStore.createDraftLeaseAgreementForNonRealhausLease(
                selectedListingId,
            );
            uiStore.hideLoading();
            if (!!leaseId) {
                uiStore.goTo(`${paths.landlord.tenants}?leaseId=${leaseId}`);
            } else {
                uiStore.error('We are unable to accept the rent application at this time');
            }
        } catch (err) {
        } finally {
            uiStore.hideLoading();
        }
    };

    return (
        <>
            <Dialog
                open={modalOpen}
                onClose={closeModal}
                maxWidth='sm'
                fullWidth={true}
                aria-labelledby='simple-modal-title'
                aria-describedby='simple-modal-description'
            >
                <DialogContent className={classes.modalContent} dividers>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant='h6'>Select a property for the lease</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl component='fieldset'>
                                <RadioGroup
                                    row
                                    aria-label='position'
                                    name='listingType'
                                    defaultValue='new'
                                    value={newListingType}
                                    onChange={(_e, v) => {
                                        setNewListingType(v);
                                    }}
                                >
                                    <FormControlLabel
                                        value='new'
                                        control={<Radio color='primary' />}
                                        label='Create New Property'
                                    />
                                    <FormControlLabel
                                        value='clone'
                                        control={<Radio color='primary' />}
                                        label='Add from Existing Property'
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={10}>
                            <InputLabel id='property-label'>Select a Listing</InputLabel>
                            <TextField
                                select
                                margin='normal'
                                id='listing'
                                fullWidth
                                required
                                variant='outlined'
                                value={selectedListingId ?? ''}
                                onChange={handleChange}
                                disabled={!isCloneProperty()}
                            >
                                {listings?.map((listing) => (
                                    <MenuItem key={listing.listingId} value={listing.listingId}>
                                        {listing.title}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeModal} color='primary'>
                        Cancel
                    </Button>
                    <Button
                        href='#'
                        color='primary'
                        variant='contained'
                        disabled={
                            isCloneProperty() && (!selectedListingId || selectedListingId === '')
                        }
                        onClick={handleAddLeaseClick}
                    >
                        Add Lease
                    </Button>
                </DialogActions>
            </Dialog>
            {/* <LeaseAgreementLandlordComponent
                leaseAgreementId={}
                onOpen={() => setIsOpen(true)}
                onClose={() => setIsOpen(false)}
                isOpen={isOpen}
            /> */}
        </>
    );
});
