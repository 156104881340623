import { makeAutoObservable } from 'mobx';
import { BillStatus, IBill, IDTransaction, IDTransactionLog, ITransaction, ITransactionLog, IWallet } from 'realhaus-sdk';
import * as apiClient from '../utils/apiClient';
import { AndQueryOperation, Collections, LimitQueryOperation, OrderByQueryOperation, OrQueryOperation, QueryOperation, StartAfterQueryOperation, WhereQueryOperation } from './dbStore';
import FirebaseStore from './firebaseStore';
import { UserStore } from './userStore';
import { DocumentData, QueryDocumentSnapshot } from 'firebase/firestore';
import { IVendorPayload } from '../utils/apiClient';


export const DEFAULT_TRANSACTIONS_LIMIT = 25;

export class WalletStore {
  constructor(private fireStore: FirebaseStore, private userStore: UserStore) {
    makeAutoObservable(this);
  }

  private defaultWallet = (uid: string): IWallet => {
    return {
      amount: 0,
      owner: uid
    }
  }

  getWallet = async () => {

    const uid = this.userStore.userId;
    if (!uid) {
      return;
    }

    const wallet = await this.fireStore.getDocument(Collections.wallets, uid);
    if (!wallet || !wallet.exists()) return this.defaultWallet(uid);

    return wallet.data() as IWallet;
  }

  getTrxActivities = async (startAfter: QueryDocumentSnapshot<DocumentData> | null): Promise<{ transactions: IDTransactionLog[], lastDoc: QueryDocumentSnapshot<DocumentData> } | undefined> => {
    const uid = this.userStore.userId;
    if (!uid) {
      return;
    }

    // queries array
    const queries: QueryOperation[] = [new LimitQueryOperation(DEFAULT_TRANSACTIONS_LIMIT), new OrderByQueryOperation('timestamp', 'desc')];

    if (startAfter?.exists()) {
      queries.push(new StartAfterQueryOperation(startAfter))
    }

    const receiverQuery = new AndQueryOperation([
      new WhereQueryOperation('receiverId', '==', uid),
      new OrQueryOperation([
        new WhereQueryOperation('type', '==', 'WITHDRAW'),
        new AndQueryOperation([new WhereQueryOperation('type', 'in', ['PAYMENT', 'TRANSFER']), new WhereQueryOperation('status', '==', 'SUCCESS')])
      ])
    ]);

    // const receiverQuery = new WhereQueryOperation('receiverId', '==', uid);
    const senderQuery = new WhereQueryOperation('senderId', '==', uid);

    const orQuery = [senderQuery, receiverQuery]
    queries.push(new OrQueryOperation(orQuery));

    const snapshot = await this.fireStore.findDocuments(`${Collections.trxLogs}`, queries);

    const transactions = snapshot.docs.map((d) => ({
      id: d.id,
      ...(d.data() as ITransactionLog)
    } as IDTransactionLog));

    return { transactions, lastDoc: snapshot.docs[snapshot.docs.length - 1] }
  }

  getWalletTransactions = async (startAfter: QueryDocumentSnapshot<DocumentData> | null): Promise<{ transactions: IDTransaction[], lastDoc: QueryDocumentSnapshot<DocumentData> } | undefined> => {
    const uid = this.userStore.userId;
    if (!uid) {
      return;
    }

    // queries array
    const queries: QueryOperation[] = [new LimitQueryOperation(DEFAULT_TRANSACTIONS_LIMIT)];

    if (startAfter?.exists()) {
      queries.push(new StartAfterQueryOperation(startAfter))
    }

    const transactionSnapshot = await this.fireStore.findDocuments(`${Collections.wallets}/${uid}/transactions`, queries);
    const transactions = transactionSnapshot.docs.map((d) => ({
      id: d.id,
      ...(d.data() as ITransaction)
    } as IDTransaction));

    return { transactions, lastDoc: transactionSnapshot.docs[transactionSnapshot.docs.length - 1] }
  }

  updateBillStatusAsPaid = async (leaseAgreementId: string, billId: string) => {
    const uid = await this.fireStore.authService.currentUser?.uid;
    if (!uid) {
      throw Error('User is not logged-in');
    }
    const path = `${Collections.leaseAgreements}/${leaseAgreementId}/${Collections.bills}`
    const billDoc = await this.fireStore.getDocument(path, billId);
    if (!billDoc.exists()) {
      throw Error('Bill not found');
    }

    if ((billDoc.data() as IBill).status !== BillStatus.PAID) {
      await this.fireStore.updateDocument(path, billId, { status: BillStatus.PAID });
      console.log("Bill Status Updated");
    }
  }

  initiateBillPayment = async (amount: number, trxFees: number, leaseId: string, billId: string, method: string, vendorPayload?: IVendorPayload) => {
    const uid = await this.fireStore.authService.currentUser?.uid;
    if (!uid) {
      throw Error('User is not logged-in!');
    }
    const token = await this.userStore.getUserToken() || '';

    return apiClient.initiateBillPayment({ token, body: { amount, leaseId, billId, method, trxFees, vendorPayload } })
  }

  getLast30DaysTransactions = async (userId: string) => {
    if (!userId) return;

    // current day
    const today = new Date();

    // thirty days ago
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - 30);

    const transactionDoc = await this.fireStore.findDocuments(`${Collections.wallets}/${userId}/transactions`, [new WhereQueryOperation('timestamp', '>=', thirtyDaysAgo.getTime())]);

    return transactionDoc.docs.map((doc) => ({
      id: doc.id,
      ...(doc.data() as ITransaction)
    } as IDTransaction))

  }

  isPositiveTransactionInLast30Days = async () => {
    const uid = await this.fireStore.authService.currentUser?.uid;
    if (!uid) throw new Error('User is not logged in');

    const thirtyDaysTransactions = await this.getLast30DaysTransactions(uid);

    if (!!thirtyDaysTransactions && thirtyDaysTransactions.length > 0) {
      for (let tranx of thirtyDaysTransactions) {
        if (tranx.amount > 0) return true;
      }
      return false;
    }
    return false;
  }
}