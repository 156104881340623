import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { MainLayout } from '../../layouts/main/main';
import { PageContent } from '../styles';
import { useStyles, PropertyDetail } from './styles';
import {
    Avatar,
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    Container,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    ImageList,
    ImageListItem,
    LinearProgress,
    Link,
    Paper,
    Typography,
} from '@material-ui/core';
import { format } from 'date-fns';
import {
    CheckCircle,
    Room,
    HotelOutlined,
    BathtubOutlined,
    SquareFootOutlined,
    LabelOutlined,
    KeyboardBackspaceOutlined,
} from '@material-ui/icons';
import StarIcon from '@material-ui/icons/Star';
import { RootStoreContext } from '../../global/storeContext';
import { useParams } from 'react-router';
import { SelectShowingTimeslot } from './showingTimeslot';
import { ShowingTimeslotCard } from '../../components/showings/showingTimeslotCards';
import { ApplyToRentButton } from '../../components/rentApplication/application';

import { HomeMap } from './ListingMap';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { toQueryStringFromArrString } from '../../utils/query';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { IRatings, GradeType } from '../../interfaces/rating';
import {
    IdPropertyListing,
    IListingTerm,
    IdShowingTimeslot,
    IPolicy,
    Pets,
    Children,
    OtherHouseRules,
} from 'realhaus-sdk';
import { PhotoGalleryModal } from '../../components/imageComponents/photoGalleryModal';
import { useIsMobile } from '../../components/uiComponents/UIComponents';
import { bedroomName } from '../../utils/property';

const MAX_LIST_SIZE = 8;
const descriptionDetailMax = 560;

export const ListingDetailPage: React.FC = observer(() => {
    const { listingStore, showingStore, userStore, uiStore, ratingStore } =
        useContext(RootStoreContext);
    let { listingId } = useParams<{ listingId: string }>(); // retrieved from url
    const [listing, setListing] = React.useState<IdPropertyListing | undefined>();
    const [leaseTerm, setLeaseTerm] = React.useState<IListingTerm | undefined>();
    const [ratings, setRatings] = React.useState<IRatings>();
    const classes = useStyles();
    const [detailTruncateable, setDetailTruncateable] = React.useState<boolean>(false);
    const [detailModalOpen, setDetailModalOpen] = React.useState<boolean>(false);
    const [showingModalOpen, setShowingModalOpen] = React.useState<boolean>(false);
    const [amenitiesModalOpen, setAmenitiesModalOpen] = React.useState<boolean>(false);
    // const [rentApplicationModalOpen, setRentApplicationModalOpen] = React.useState<boolean>(false);
    const [reservedTimeslot, setReservedTimeslot] = React.useState<IdShowingTimeslot>();
    const [showMoreHouseRules, setShowMoreHouseRules] = React.useState<boolean>(false);
    const [showMoreRatings, setShowMoreRatings] = React.useState<boolean>(false);

    const [galleryStops, setGalleryStops] = useState<number[]>([]);
    const imageListRef = React.createRef<HTMLUListElement>();
    const navigate = useNavigate();

    const [isSaved, setIsSaved] = useState<boolean>(false);

    const [galleryOpen, setGalleryOpen] = React.useState(false);
    const [clickedImageIndex, setClickedImageIndex] = React.useState(0);

    const [alreadyLeased, setAlreadyLeased] = useState<boolean>();

    const isMyListing = () =>
        !!listing && !!userStore.userId && listing.ownerId === userStore.userId;
    const handleDetailClose = () => {
        setDetailModalOpen(false);
    };

    const isMobile = useIsMobile();

    const handleDetailOpen = () => {
        setDetailModalOpen(true);
    };

    const handleShowingModalOpen = () => {
        setShowingModalOpen(true);
    };
    const handleShowingModalClose = () => {
        setShowingModalOpen(false);
    };

    const handleGalleryOpen = (index: number) => {
        setGalleryOpen(true);
        setClickedImageIndex(index);
    };

    const handleGalleryClose = () => {
        setGalleryOpen(false);
    };

    useEffect(() => {
        if (!listingId) {
            return;
        }

        Promise.all([
            listingStore.getListing(listingId),
            listingStore.getListingTerm(listingId),
            listingStore.getBookmarkedListings(),
            ratingStore.calculateListingRating(listingId),
            listingStore.hasActiveLeaseAgreement(listingId),
        ]).then(([l, term, bookmarkedListingIds, listingRatings, hasActiveLease]) => {
            if (!l) return uiStore.error('Unable to find Listing');

            setDetailTruncateable(textTruncateable(descriptionDetailMax, l.description));
            setListing(l);

            // get reserved timeslot
            showingStore.reservedTimeslotByListing(l.listingId).then((ts) => {
                setReservedTimeslot(ts);
            });

            setIsSaved(bookmarkedListingIds.some((x) => x === listingId));

            setLeaseTerm(term);

            setRatings(listingRatings);

            setAlreadyLeased(hasActiveLease);
        });
    }, [listingId, listingStore, showingStore, ratingStore]);

    const generateStops = () => {
        if (galleryStops.length > 0) {
            return galleryStops;
        }
        var stepSize = 2;
        const colWidth = (imageListRef.current?.offsetWidth ?? 0) / 4;
        const stops = [0]; /* first element at 0px = first stop */
        stops.push(3 + colWidth * 2);
        for (var i = 0; i < Math.ceil(((listing?.photos.length ?? 0) - 1) / 2); i += stepSize) {
            stops.push(Math.ceil(3 * stepSize + stops[stops.length - 1] + colWidth * stepSize));
        }
        setGalleryStops(stops);

        return stops;
    };

    const scrollGalleryDirection = (direction: string) => {
        const stops = generateStops();

        let curScroll = imageListRef.current?.scrollLeft ?? 0;
        switch (direction) {
            case 'left': {
                for (let i = stops.length - 1; i >= 0; i--) {
                    if (stops[i] < curScroll) {
                        scrollGallery(stops[i]);
                        break;
                    }
                }

                break;
            }
            case 'right': {
                for (let i = 0; i < stops.length; i++) {
                    if (stops[i] > Math.ceil(curScroll + 0.1)) {
                        scrollGallery(stops[i]);
                        break;
                    }
                }
                break;
            }
        }
    };
    const scrollGallery = (xValue: number) => {
        if (xValue != null) {
            imageListRef.current?.scroll(xValue, 0);
        }
    };

    const mobilePhotos = !!listing ? listing.photos : [];

    const scrollMobileGalleryDirection = (direction: string, index: number) => {
        switch (direction) {
            case 'left': {
                if (index === 0) {
                    return;
                }
                setClickedImageIndex(clickedImageIndex - 1);
                break;
            }
            case 'right': {
                if (index === mobilePhotos.length - 1) {
                    return;
                }
                setClickedImageIndex(clickedImageIndex + 1);
                break;
            }
        }
    };

    const MobileGallery = () => {
        return (
            <>
                {!listing ? null : (
                    <div className={classes.gridRoot}>
                        <div className={classes.gridList}>
                            <img
                                src={mobilePhotos[clickedImageIndex].url}
                                alt={listing.title}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                }}
                                onClick={() => handleGalleryOpen(clickedImageIndex)}
                            />
                        </div>

                        <div style={{ width: '100%', height: '100%' }}>
                            <IconButton
                                onClick={() => {
                                    scrollMobileGalleryDirection('left', clickedImageIndex);
                                }}
                                className={classes.imageGridNavButton}
                                style={{ left: '5px' }}
                            >
                                <ChevronLeftIcon fontSize='large' />
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    scrollMobileGalleryDirection('right', clickedImageIndex);
                                }}
                                className={classes.imageGridNavButton}
                                style={{ right: '5px' }}
                            >
                                <ChevronRightIcon fontSize='large' />
                            </IconButton>
                        </div>
                        <PhotoGalleryModal
                            title={listing.title}
                            isOpen={galleryOpen}
                            handleClose={handleGalleryClose}
                            listingPhotos={listing.photos}
                            clickedImageIndex={clickedImageIndex}
                        />
                    </div>
                )}
            </>
        );
    };

    const GalleryGrid = () => {
        return (
            <>
                {!listing ? null : (
                    <div className={classes.gridRoot}>
                        <ImageList
                            ref={imageListRef}
                            rowHeight={167}
                            cols={4}
                            gap={1.5}
                            className={classes.gridList}
                        >
                            {listing.photos.map((tile, index) => (
                                <ImageListItem
                                    key={tile.id}
                                    rows={index === 0 ? 2 : 1}
                                    cols={index === 0 ? 2 : 1}
                                    className='imageList'
                                >
                                    <img
                                        src={tile.url}
                                        alt={listing.title}
                                        onClick={() => handleGalleryOpen(index)}
                                    />
                                </ImageListItem>
                            ))}
                        </ImageList>
                        <div style={{ width: '100%', height: '100%' }}>
                            <IconButton
                                onClick={() => {
                                    scrollGalleryDirection('left');
                                }}
                                className={classes.imageGridNavButton}
                                style={{ left: '5px' }}
                            >
                                <ChevronLeftIcon fontSize='large' />
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    scrollGalleryDirection('right');
                                }}
                                className={classes.imageGridNavButton}
                                style={{ right: '5px' }}
                            >
                                <ChevronRightIcon fontSize='large' />
                            </IconButton>
                        </div>
                        <PhotoGalleryModal
                            title={listing.title}
                            isOpen={galleryOpen}
                            handleClose={handleGalleryClose}
                            listingPhotos={listing.photos}
                            clickedImageIndex={clickedImageIndex}
                        />
                    </div>
                )}
            </>
        );
    };

    const locationQueryArr = (arr: string[]) => {
        const q = toQueryStringFromArrString(arr);
        return `/search?q=${q}`;
    };

    const toggleBookmark = (listingId?: string) => {
        if (isSaved) {
            listingStore.removeBookmarkedListing(listingId ?? '').then(() => {
                uiStore.success('Bookmark updated');
                setIsSaved(false);
            });
        } else {
            listingStore.createBookmark(listingId ?? '').then(() => {
                uiStore.success('Bookmark updated');
                setIsSaved(true);
            });
        }
    };

    const PropertyBreadcrumb = () => {
        return (
            <Typography
                display='inline'
                variant='subtitle1'
                className={classes.listingBreadcrumb}
                gutterBottom
            >
                <Link
                    color='inherit'
                    href='#'
                    onClick={(e) => {
                        e.preventDefault();
                        navigate(-1);
                    }}
                    style={{ display: 'flex', alignItems: 'center' }}
                >
                    <KeyboardBackspaceOutlined fontSize='small' /> {'  '}
                    <b>Back</b>
                </Link>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Breadcrumbs separator='›' aria-label='breadcrumb'>
                    {listing?.address.province && (
                        <Link color='inherit' href={locationQueryArr([listing?.address.province])}>
                            {listing?.address.province}
                        </Link>
                    )}
                    {listing?.address.city && (
                        <Link
                            color='inherit'
                            href={locationQueryArr([
                                listing?.address.city,
                                listing?.address.province,
                            ])}
                        >
                            {listing?.address.city}
                        </Link>
                    )}
                    {listing?.address.neighborhood && (
                        <Link
                            color='inherit'
                            href={locationQueryArr([
                                listing?.address.neighborhood,
                                listing?.address.city,
                                listing?.address.province,
                            ])}
                        >
                            {listing?.address.neighborhood}
                        </Link>
                    )}
                </Breadcrumbs>
            </Typography>
        );
    };
    const BookmarkIconComponent = () => {
        return (
            <Typography variant='subtitle1' className={classes.listingBreadcrumb} gutterBottom>
                <Link
                    color='inherit'
                    href='#'
                    onClick={(e) => {
                        e.preventDefault();
                        toggleBookmark(listingId);
                    }}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        textDecoration: 'none',
                    }}
                >
                    {isSaved ? (
                        <>
                            <FavoriteIcon fontSize='small' />
                            <span style={{ paddingLeft: '5px' }}>Saved</span>
                        </>
                    ) : (
                        <>
                            <FavoriteBorderIcon fontSize='small' />
                            <span style={{ paddingLeft: '5px' }}>Save</span>
                        </>
                    )}
                </Link>
            </Typography>
        );
    };

    const PropertyHeader = () => {
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item md={10} xs={12}>
                        <PropertyBreadcrumb />
                        {/* review goes here */}
                        {/* <Typography variant='h6'>
                            <StarIcon />
                            N/A
                        </Typography> */}
                    </Grid>
                    <Grid item md={2} xs={12}>
                        <BookmarkIconComponent />
                    </Grid>
                </Grid>
                <Grid container spacing={2} className={classes.gutterBottom}>
                    <Typography variant='h5' gutterBottom>
                        {listing?.title}
                    </Typography>
                </Grid>
            </>
        );
    };

    const FullAddress = () => {
        return (
            <>
                <Grid container alignItems='center' className={classes.gutterBottom}>
                    <Grid item container md={12} alignItems='center' style={{ display: 'flex' }}>
                        <Room style={{ paddingRight: '5px' }} />
                        <Typography variant='subtitle1' display='inline'>
                            {` ${listing?.address.streetAddress}, ${listing?.address.city}, ${listing?.address.province}`}
                        </Typography>
                    </Grid>
                </Grid>
            </>
        );
    };

    const PropertySpec = () => {
        return (
            <Paper variant='outlined' className={classes.propertySpec}>
                <Grid container spacing={2}>
                    <Grid item xs={2} md={3}>
                        <Typography
                            variant='subtitle1'
                            className={classes.alignCenter}
                            gutterBottom
                        >
                            <HotelOutlined />
                            &nbsp;
                            {listing?.isSharedProperty
                                ? `1 (${bedroomName(listing.bedroomForRent)})`
                                : listing?.bedrooms}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography
                            variant='subtitle1'
                            className={classes.alignCenter}
                            gutterBottom
                        >
                            <BathtubOutlined />
                            &nbsp;
                            {listing?.isSharedProperty
                                ? `1 (${listing.bathroomPrivacy})`
                                : listing?.bathrooms}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} md={3}>
                        <Typography
                            variant='subtitle1'
                            className={classes.alignCenter}
                            gutterBottom
                        >
                            <SquareFootOutlined />
                            &nbsp;
                            {listing?.size} {`sq.ft`}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography
                            variant='subtitle1'
                            className={classes.alignCenter}
                            gutterBottom
                        >
                            <b>built</b>
                            &nbsp;
                            {listing?.yearBuilt}
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
        );
    };

    const truncateText = (max: number, text?: string) => {
        if (text && text.length > max) {
            return text.substr(0, max - 1) + ' ...';
        }
        return text;
    };

    const textTruncateable = (max: number, text?: string) => {
        return !!text && text.length > max;
    };

    const PropertyDetails = () => {
        return (
            <>
                <PropertyDetail className={classes.gutterBottom}>
                    <Grid container spacing={2} className={classes.gutterBottom}>
                        <Grid item>
                            <Typography variant='body1' gutterBottom align='justify'>
                                {truncateText(descriptionDetailMax, listing?.description)}
                            </Typography>
                        </Grid>
                        <Grid item>
                            {detailTruncateable ? (
                                <Button onClick={handleDetailOpen} color='primary'>
                                    read more
                                </Button>
                            ) : null}
                        </Grid>
                    </Grid>
                </PropertyDetail>
            </>
        );
    };

    const Amenities = () => {
        const showAllAmenities = () => {
            if (listing?.amenities)
                return listing?.amenities.length > 0 && listing?.amenities.length > 8;
        };

        return (
            <>
                <Grid container spacing={2} className={classes.gutterBottom}>
                    <Grid item xs={12}>
                        <Typography variant='h5' style={{ fontWeight: 'bold' }} gutterBottom>
                            Amenities
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            {listing?.amenities.slice(0, 8).map((amenity, i) => (
                                <Grid
                                    xs={12}
                                    md={6}
                                    item
                                    className={classes.amenityContainer}
                                    key={`amenity-${i}`}
                                >
                                    <CheckCircle color='primary' className={classes.amenityIcon} />
                                    <Typography variant='subtitle1'>{amenity.value}</Typography>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    {showAllAmenities() && (
                        <Grid item xs={12}>
                            <Button onClick={() => setAmenitiesModalOpen(true)} color='primary'>
                                show more
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </>
        );
    };

    const PropertyHouseRules = ({ leaseTerm }: { leaseTerm: IListingTerm | undefined }) => {
        const showAllHouseRules = () => {
            if (!leaseTerm) return false;
            return (
                leaseTerm?.features?.houseRules.length > 0 &&
                leaseTerm?.features?.houseRules.length > MAX_LIST_SIZE
            );
        };

        const ruleObj: { [key: string]: IPolicy[] } = { pets: [], children: [], others: [] };
        if (!!leaseTerm && leaseTerm.features && leaseTerm.features.houseRules) {
            for (const rule of leaseTerm?.features.houseRules) {
                // for pets
                const isPet = Pets.some((val) => val === rule.text);
                // for children
                const isChildren = Children.some((val) => val === rule.text);
                if (isPet) {
                    ruleObj.pets.push(rule);
                } else if (isChildren) {
                    ruleObj.children.push(rule);
                } else {
                    ruleObj.others.push(rule);
                }
            }
        }
        const rulesTextArr = Object.entries(ruleObj).flatMap((rule: [string, IPolicy[]]) => {
            const arrText: string[] = [];
            switch (rule[0]) {
                case 'pets':
                    if (rule[1].length > 0 && rule[1].length < Pets.length) {
                        const petsString = rule[1].map((r) => r.text);
                        const text = `${petsString.join(', ')} allowed`;
                        arrText.push(text);
                    } else if (rule[1].length === 0 || rule[1].length < 0) {
                        arrText.push('No pets allowed');
                    } else if (rule[1].length === Pets.length) {
                        arrText.push('Pets allowed');
                    }
                    break;
                case 'children':
                    if (rule[1].length > 0 && rule[1].length < Children.length) {
                        const childrenString = rule[1].map((r) => r.text);
                        const text = `${childrenString.join(', ')}`;
                        arrText.push(text);
                    } else if (rule[1].length === 0 || rule[1].length < 0) {
                        arrText.push('No children allowed');
                    } else if (rule[1].length === Children.length) {
                        arrText.push('Suitable for all age ranges of children');
                    }
                    break;
                case 'others':
                    if (rule[1].length > 0) {
                        const othersTextArr = rule[1].map((r) => r.text);
                        othersTextArr.forEach((r) => {
                            arrText.push(`${r} allowed`);
                        });
                        //     handle not allowed
                        const filtOthers = OtherHouseRules.filter(
                            (rule) => !othersTextArr.includes(rule),
                        );
                        if (filtOthers.length > 0) {
                            filtOthers.forEach((other) =>
                                arrText.push(`No ${other.toLowerCase()} allowed`),
                            );
                        }
                    } else if (rule[1].length === 0 || rule[1].length < 0) {
                        OtherHouseRules.forEach((other) => {
                            arrText.push(`No ${other.toLowerCase()} allowed`);
                        });
                    }
                    break;
            }
            return arrText;
        });

        return (
            <>
                {leaseTerm && leaseTerm.features && leaseTerm.features.houseRules && (
                    <Grid container spacing={2} className={classes.gutterBottom}>
                        <Grid item xs={12}>
                            <Typography variant='h5' style={{ fontWeight: 'bold' }} gutterBottom>
                                House rules
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                {rulesTextArr.slice(0, MAX_LIST_SIZE).map((rule, i) => (
                                    <Grid item xs={12} md={6} key={`property-rule-${i}`}>
                                        <PropertyHouseRuleLabel rule={rule} />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                        {showAllHouseRules() && (
                            <Grid item xs={12}>
                                <Button onClick={toggleMoreHouseRules} color='primary'>
                                    Show more
                                </Button>
                            </Grid>
                        )}
                        <Dialog
                            open={showMoreHouseRules}
                            onClose={toggleMoreHouseRules}
                            maxWidth='sm'
                            fullWidth={true}
                            aria-labelledby='house-rules-modal'
                            aria-describedby='show-more-house-rules-modal'
                        >
                            <DialogTitle>House Rules</DialogTitle>
                            <DialogContent>
                                <Grid container spacing={2}>
                                    {rulesTextArr.map((rule, i) => (
                                        <PropertyHouseRuleLabel
                                            rule={rule}
                                            key={`modal-property-rule-${i}`}
                                        />
                                    ))}
                                </Grid>
                            </DialogContent>
                        </Dialog>
                    </Grid>
                )}
            </>
        );
    };

    const PropertyHouseRuleLabel = ({ rule }: { rule: string }) => {
        return (
            <>
                <Grid container item spacing={2} direction='row'>
                    <Grid item>
                        <LabelOutlined />
                    </Grid>
                    <Grid item>
                        <Typography>{rule}</Typography>
                    </Grid>
                </Grid>
            </>
        );
    };

    const toggleMoreHouseRules = () => {
        setShowMoreHouseRules(!showMoreHouseRules);
    };

    const toggleMoreRatings = () => {
        setShowMoreRatings(!showMoreRatings);
    };

    const ListingRating = ({ ratings }: { ratings?: IRatings }) => {
        const ListingGrades = () => {
            return (
                <Grid container item xs={12} spacing={2}>
                    {Object.keys(ratings?.averageGrades || {})
                        .sort()
                        .map((gradeType, i) => (
                            <Grid item container xs={12} sm={6} key={`rating-grade-type-${i}`}>
                                <Grid item container xs={12} sm={6}>
                                    {gradeType}
                                </Grid>
                                <Grid item container xs={12} sm={6}>
                                    <Box display='flex' width='100%' alignItems='center'>
                                        <Box width='100%' mr={1}>
                                            <LinearProgress
                                                variant='determinate'
                                                value={
                                                    ratings?.averageGrades[
                                                        gradeType as GradeType
                                                    ] &&
                                                    (ratings?.averageGrades[
                                                        gradeType as GradeType
                                                    ] *
                                                        100) /
                                                        ratings?.maxRating
                                                }
                                            />
                                        </Box>
                                        <Box minWidth={35}>
                                            <Typography variant='body2' color='textSecondary'>
                                                {ratings?.averageGrades[
                                                    gradeType as GradeType
                                                ].toFixed(1)}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        ))}
                </Grid>
            );
        };
        return (
            <>
                <Grid container spacing={2} className={classes.gutterBottom}>
                    <Grid item xs={12}>
                        <Typography variant='h5' style={{ fontWeight: 'bold' }} gutterBottom>
                            Ratings and Reviews
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h6' style={{ fontWeight: 'bold' }} gutterBottom>
                            {ratings?.rating ? (
                                <>
                                    {ratings?.rating.toFixed(2)}
                                    <StarIcon fontSize='small' style={{ marginBottom: '-2px' }} />
                                </>
                            ) : (
                                'No Ratings'
                            )}{' '}
                            | {ratings?.reviews.length || 'No'} Reviews
                        </Typography>
                    </Grid>
                    <Grid item container xs={12} spacing={2}>
                        <ListingGrades />
                        <Box sx={{ width: '100%', marginTop: '1rem' }}></Box>
                        <Grid container item xs={12} spacing={2}>
                            {ratings?.reviews.slice(0, 4).map(({ review, date, firstName }, i) => (
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    spacing={1}
                                    sm={6}
                                    key={`listing-review-${i}`}
                                >
                                    <Grid
                                        item
                                        container
                                        xs={12}
                                        spacing={1}
                                        justifyContent='flex-start'
                                        direction='row'
                                    >
                                        <Grid item>
                                            <Avatar
                                                alt={`Reviewer ${i + 1} Profile Picture`}
                                                src={`https://v4.mui.com/static/images/avatar/${
                                                    i + 1
                                                }.jpg`}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Grid item container xs={12} spacing={0}>
                                                <Typography
                                                    variant='body1'
                                                    style={{ fontWeight: 'bold' }}
                                                >
                                                    {firstName}
                                                </Typography>
                                            </Grid>
                                            <Grid item container xs={12} spacing={0}>
                                                <Typography
                                                    variant='subtitle2'
                                                    style={{ fontWeight: 'lighter' }}
                                                >
                                                    {date}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {review.slice(0, 100)}
                                        {review.length > 100 && '...'}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button onClick={toggleMoreRatings} color='primary'>
                                            Show more
                                        </Button>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Dialog
                        open={showMoreRatings}
                        onClose={toggleMoreRatings}
                        maxWidth='md'
                        fullWidth={true}
                        aria-labelledby='ratings-and-reviews-modal'
                        aria-describedby='show-more-ratings-and-reviews-modal'
                    >
                        <DialogTitle>Ratings and Reviews</DialogTitle>
                        <DialogContent>
                            <Grid item xs={12}>
                                <Typography
                                    variant='h4'
                                    style={{ fontWeight: 'bold' }}
                                    gutterBottom
                                >
                                    {ratings?.rating && (
                                        <>
                                            {ratings?.rating.toFixed(2)}
                                            <StarIcon
                                                fontSize='small'
                                                style={{
                                                    marginBottom: '-2px',
                                                    transform: 'scale(1.5)',
                                                    padding: '5px',
                                                }}
                                            />
                                        </>
                                    )}{' '}
                                    | {ratings?.reviews.length} Reviews
                                </Typography>
                                <Grid item container xs={12} spacing={2}>
                                    <ListingGrades />
                                    <Box sx={{ width: '100%', marginTop: '1rem' }}></Box>
                                    <Grid container item xs={12} spacing={3}>
                                        {ratings?.reviews.map(({ review, date, firstName }, i) => (
                                            <Grid
                                                item
                                                container
                                                spacing={1}
                                                xs={12}
                                                sm={6}
                                                key={`listing-review-${i}`}
                                            >
                                                <Grid
                                                    item
                                                    container
                                                    xs={12}
                                                    spacing={2}
                                                    justifyContent='flex-start'
                                                    direction='row'
                                                >
                                                    <Grid item>
                                                        <Avatar
                                                            alt={`Reviewer Profile Picture ${
                                                                i + 1
                                                            }`}
                                                            src={`https://v4.mui.com/static/images/avatar/${
                                                                i + 1
                                                            }.jpg`}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid item container xs={12} spacing={0}>
                                                            <Typography
                                                                variant='body1'
                                                                style={{ fontWeight: 'bold' }}
                                                            >
                                                                {firstName}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item container xs={12} spacing={0}>
                                                            <Typography
                                                                variant='subtitle2'
                                                                style={{ fontWeight: 'lighter' }}
                                                            >
                                                                {date}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {review}
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                </Grid>
            </>
        );
    };

    const handleCancelReservedShowing = (timeslotId: string) => () => {
        if (!listing?.propertyId) return;
        showingStore.cancelReservedShowingByListing(listing?.listingId, timeslotId).then(() => {
            uiStore.success('Your reservation has been cancelled');
            setReservedTimeslot(undefined);
        });
    };

    const handleShowingScheduled = (timeslot: IdShowingTimeslot) => {
        userStore.getProfile().then((userInfo) => {
            handleShowingModalClose();
            setReservedTimeslot({
                ...timeslot,
                reservedBy: {
                    id: userStore.userId ?? '',
                    name: `${userInfo?.firstname} ${userInfo?.lastname}`,
                },
            });
            uiStore.success('Showing has been reserved');
        });
    };

    const handleRentApplicationSubmitted = () => {
        uiStore.success('Your rent application has been submitted');
        // handleRentApplicationModalClose();
    };

    const ListingTerms = () => {
        return (
            <>
                {!!alreadyLeased && !listing?.isListed ? (
                    <Typography variant='h6' align='center' gutterBottom>
                        This Listing is no longer available.
                    </Typography>
                ) : (
                    <Grid container spacing={1} className={classes.listingTermContent}>
                        <Grid item xs={12} className={classes.listingTerm}>
                            <Typography
                                variant='button'
                                display='block'
                                color='textSecondary'
                                gutterBottom
                            >
                                Available
                            </Typography>
                            <Typography variant='button' display='block' gutterBottom>
                                {!!leaseTerm?.dateAvailable
                                    ? leaseTerm.dateAvailable <= Date.now()
                                        ? 'Immediately'
                                        : format(leaseTerm?.dateAvailable, 'PP')
                                    : null}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.listingTerm}>
                            <Typography
                                variant='button'
                                display='block'
                                color='textSecondary'
                                gutterBottom
                            >
                                Minimum Term
                            </Typography>
                            <Typography variant='button' display='block' gutterBottom>
                                {leaseTerm?.duration} {leaseTerm?.durationType ?? 'Months'}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.listingTerm}>
                            <Typography
                                variant='button'
                                display='block'
                                color='textSecondary'
                                gutterBottom
                            >
                                Rent
                            </Typography>
                            <Typography variant='subtitle2' gutterBottom>
                                $ {leaseTerm?.rentAmount} /mo
                            </Typography>
                        </Grid>
                        {!listing?.propertyId || isMyListing() ? null : (
                            <>
                                <Grid item xs={12}>
                                    {!!reservedTimeslot ? (
                                        <Grid
                                            container
                                            direction='row'
                                            justifyContent='center'
                                            alignItems='center'
                                        >
                                            <Grid item>
                                                <ShowingTimeslotCard
                                                    timeslot={reservedTimeslot}
                                                    handleCancelTimeslot={handleCancelReservedShowing(
                                                        reservedTimeslot.id,
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <Button
                                            variant='outlined'
                                            color='primary'
                                            onClick={handleShowingModalOpen}
                                        >
                                            Schedule Showing
                                        </Button>
                                    )}
                                </Grid>

                                <Grid item xs={12}>
                                    <Button variant='outlined' color='primary'>
                                        Message Landlord
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    {listingId && (
                                        <ApplyToRentButton
                                            listingId={listingId}
                                            leaseTerm={leaseTerm}
                                            onSubmitted={handleRentApplicationSubmitted}
                                        />
                                    )}
                                </Grid>
                            </>
                        )}
                    </Grid>
                )}
            </>
        );
    };

    return !listingId ? (
        <></>
    ) : (
        <>
            <MainLayout>
                <PageContent>
                    <section>
                        <Container maxWidth='md'>
                            <PropertyHeader />
                        </Container>
                    </section>
                    <section>
                        <Container maxWidth='lg'>
                            {/* <PropertyHeader /> */}
                            {!isMobile ? <GalleryGrid /> : <MobileGallery />}
                        </Container>
                    </section>
                    {/* <Toolbar /> */}
                    <section>
                        <Container maxWidth='md'>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={9}>
                                    <FullAddress />
                                    <PropertySpec />
                                    {isMobile && (
                                        <Card variant='outlined'>
                                            <CardContent>
                                                {/* Add listing terms and details */}
                                                <ListingTerms />
                                                {/* Add showing info */}
                                            </CardContent>
                                        </Card>
                                    )}
                                    <PropertyDetails />
                                    <Divider className={classes.gutterBottom} />
                                    <Amenities />
                                    <Divider className={classes.gutterBottom} />
                                    <PropertyHouseRules leaseTerm={leaseTerm} />
                                </Grid>
                                {!isMobile && (
                                    <Grid item xs={12} md={3}>
                                        <Card variant='outlined'>
                                            <CardContent>
                                                {/* Add listing terms and details */}
                                                <ListingTerms />
                                                {/* Add showing info */}
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                )}
                            </Grid>
                        </Container>
                    </section>
                    <section>
                        <Container maxWidth='md'>
                            <Grid
                                container
                                spacing={2}
                                className={classes.gutterBottom}
                                direction='column'
                            >
                                <Grid item>
                                    <Divider className={classes.gutterBottom} />
                                    <ListingRating ratings={ratings} />
                                </Grid>
                                <Grid item>
                                    <Divider className={classes.gutterBottom} />
                                    <HomeMap
                                        centerCordinate={
                                            listing?.address.geoloc || { lat: 0, lng: 0 }
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Container>
                    </section>
                    {/* Property Details Modal */}
                    <Dialog
                        open={detailModalOpen}
                        onClose={handleDetailClose}
                        aria-labelledby='simple-modal-title'
                        aria-describedby='simple-modal-description'
                    >
                        <DialogTitle>About this property</DialogTitle>
                        <DialogContent>
                            <PropertyDetail>
                                <Typography variant='body1' gutterBottom align='justify'>
                                    {listing?.description}
                                </Typography>
                            </PropertyDetail>
                        </DialogContent>
                    </Dialog>
                    {/* Amenities Modal */}
                    <Dialog
                        open={amenitiesModalOpen}
                        onClose={() => setAmenitiesModalOpen(false)}
                        maxWidth='sm'
                        fullWidth={true}
                        aria-labelledby='simple-modal-title'
                        aria-describedby='simple-modal-description'
                    >
                        <DialogTitle>Amenities</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2}>
                                {listing?.amenities.map((amenity, i) => (
                                    <Grid
                                        md={6}
                                        item
                                        className={classes.amenityContainer}
                                        key={`modal-amenity-${i}`}
                                    >
                                        <CheckCircle
                                            color='primary'
                                            className={classes.amenityIcon}
                                        />
                                        <Typography variant='subtitle1'>{amenity.value}</Typography>
                                    </Grid>
                                ))}
                            </Grid>
                        </DialogContent>
                    </Dialog>
                    {/* Showing Scheduling Modal */}
                    <Dialog
                        open={showingModalOpen}
                        onClose={handleShowingModalClose}
                        maxWidth='sm'
                        fullWidth={true}
                        aria-labelledby='simple-modal-title'
                        aria-describedby='simple-modal-description'
                    >
                        <DialogTitle>Schedule Showing</DialogTitle>
                        <DialogContent>
                            {!listing?.propertyId ? null : (
                                <SelectShowingTimeslot
                                    listingId={listingId}
                                    propertyId={listing?.propertyId}
                                    onShowingScheduled={handleShowingScheduled}
                                />
                            )}
                        </DialogContent>
                    </Dialog>
                    {/* Rental Application Modal */}
                    {/* <Dialog
                            open={rentApplicationModalOpen}
                            onClose={handleRentApplicationModalClose}
                            maxWidth='md'
                            fullWidth={true}
                            aria-labelledby='simple-modal-title'
                            aria-describedby='simple-modal-description'
                        >
                            <DialogTitle>Complete Rent Application</DialogTitle>
                            <DialogContent>
                                {!listing?.propertyId ? null : (
                                    <RentApplicationComponent
                                        listingId={listingId}
                                        onSubmitted={handleRentApplicationSubmitted}
                                    />
                                )}
                            </DialogContent>
                        </Dialog> */}
                </PageContent>
            </MainLayout>
        </>
    );
});
