import React from 'react';
import { Icon } from '@material-ui/core';
// const interacIcon = '../../../img/vendor_icons/InteracLogo.svg';
// const stripeIcon = '../../../img/vendor_icons/Stripe_Logo.svg';
// const paypalIcon = '../../../img/vendor_icons/PayPal.svg';
const ccIcon = '../../../img/payment_icons/credit-card-icon.svg';
const eftIcon = '../../../img/payment_icons/eft-bank-icon.svg';
const interacIcon =
    'https://library.paramountcommerce.com/pc_mark_library/interac/square_logo/logo_interac_48x48_color_can.svg'; //'../../../img/payment_icons/interac-icon.svg';

const SmallIconImage: React.FC<{ src: string }> = ({ src }) => {
    return (
        <Icon>
            <img src={src} alt='Icon' height={30} width={30} style={{ verticalAlign: 'middle' }} />
        </Icon>
    );
};

const LargeIconImage: React.FC<{ src: string }> = ({ src }) => {
    return (
        <Icon>
            <img src={src} alt='Icon' height={60} width={60} style={{ verticalAlign: 'middle' }} />
        </Icon>
    );
};

export const LargeInteracIcon: React.FC = () => {
    return <LargeIconImage src={interacIcon} />;
};

export const LargeEFTIcon: React.FC = () => {
    return <LargeIconImage src={eftIcon} />;
};

export const LargeCreditCardIcon: React.FC = () => {
    return <LargeIconImage src={ccIcon} />;
};

export const SmallInteracIcon: React.FC = () => {
    return <SmallIconImage src={interacIcon} />;
};

export const SmallEFTIcon: React.FC = () => {
    return <SmallIconImage src={eftIcon} />;
};

export const SmallCreditCardIcon: React.FC = () => {
    return <SmallIconImage src={ccIcon} />;
};
