import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import AddIcon from '@material-ui/icons/Add';
import {
    Button,
    Checkbox,
    FormControl,
    Grid,
    ListItemText,
    MenuItem,
    Select,
    Typography,
} from '@material-ui/core';
import {
    IdLeaseAgreement,
    LeaseAgreementStatus,
    LeaseAgreementViewer,
    ITenantInfo,
    ITenantLeaseInvite,
    LeaseAgreementSource,
} from 'realhaus-sdk';
import { AdminPageLayout, PageTitle } from '../../../components/uiComponents/UIComponents';
import { useQuery } from '../../../utils/route';
import { RootStoreContext } from '../../../global/storeContext';
import { streetAddress } from '../../../utils/address';
import { LeaseSnapshot } from '../../../components/lease/leaseSnapshot';
import { LeaseAgreementLandlordComponent } from '../../../components/leaseAgreement/leaseAgreementLandlord';
import { LeaseAgreementDetailComponent } from '../../../components/leaseAgreement/details/leaseAgreementDetail';
import { AddTenantLeaseInvite } from '../../../components/lease/AddTenantLeaseInvite';
import { CompleteAccountSetupDialog } from '../../../components/rentApplication/dialogs/completeAccountSetupDialog';

const LeaseStatuses = [
    { key: 'draft', Text: 'Draft' },
    { key: 'active', Text: 'Active' },
    { key: 'inactive', Text: 'Inactive' },
];

/**
 * Leases displays a list of lease
 */
const Leases: React.FC = observer(() => {
    const [leaseAgreementModalOpen, setLeaseAgreementModalOpen] = React.useState<boolean>(false);
    const [leaseAgreementDetailOpen, setLeaseAgreementDetailOpen] = React.useState<boolean>(false);
    const [selectedLeaseId, setSelectedLeaseId] = useState<string | undefined>();
    const { leaseStore } = useContext(RootStoreContext);
    const [tenants, setTenants] = useState<Map<string, ITenantInfo>>(
        new Map<string, ITenantInfo>(),
    );
    const [leaseInviteTenantInfo, setLeaseInviteTenantInfo] = useState<
        Map<string, ITenantLeaseInvite>
    >(new Map<string, ITenantLeaseInvite>());
    const [leases, setLeases] = useState<IdLeaseAgreement[]>();
    const [filteredLeases, setFilteredLeases] = useState<IdLeaseAgreement[]>();
    const query = useQuery();

    const [statusFilter, setStatusFilter] = React.useState<string[]>(
        LeaseStatuses.filter((f) => f.key == 'draft' || f.key == 'active').map((s) => s.key),
    );
    const [propertyFilter, setPropertyFilter] = React.useState<string>('all');

    useEffect(() => {
        if (query.has('leaseId')) {
            const leaseId = query.get('leaseId');
            if (leaseId) {
                setSelectedLeaseId(leaseId);
            }
        }

        const init = async () => {
            const leaseAgreements = await fetchLeaseAgreements();

            const tenantIds: string[] = [];

            const leasesWithInvites: string[] = [];
            leaseAgreements?.forEach((l) => {
                if (Array.isArray(l.tenantIds) && l.tenantIds.length > 0) {
                    tenantIds.push(...l.tenantIds.filter((id) => !tenantIds.find((x) => x === id)));
                }

                // retain leasesWithInvites that have not been acknowledged by tenant
                if (
                    l.source === LeaseAgreementSource.NON_REALHAUS &&
                    (!Array.isArray(l.tenantIds) || !l.tenantIds || l.tenantIds.length === 0)
                ) {
                    leasesWithInvites.push(l.id);
                }
            });

            const tenants = await Promise.all(
                tenantIds.map(async (tenantId) => {
                    const tenant = await leaseStore.getTenantInfo(tenantId);
                    return Promise.resolve({ id: tenantId, tenant: tenant });
                }),
            );

            const tMap = new Map<string, ITenantInfo>();
            tenants.forEach((tenant) => {
                if (!tMap.has(tenant.id) && !!tenant.tenant) {
                    tMap.set(tenant.id, tenant.tenant);
                }
            });
            setTenants(tMap);

            // get lease Invites
            if (leaseAgreements && leasesWithInvites.length > 0) {
                const info = await Promise.all(
                    leasesWithInvites.map(async (leaseId) => {
                        // Fetch lease invitation info for non realhaus leases
                        const inviteInfo = await leaseStore.getTenantInvitationInfo(leaseId);
                        return Promise.resolve({ leaseId, inviteInfo });
                    }),
                );
                const tInviteMap = new Map<string, ITenantLeaseInvite>();
                info.forEach((i) => {
                    if (!tInviteMap.has(i.leaseId) && !!i.inviteInfo) {
                        tInviteMap.set(i.leaseId, i.inviteInfo);
                    }
                });

                setLeaseInviteTenantInfo(tInviteMap);
            }
        };
        init();
    }, []);

    useEffect(() => {
        setLeaseAgreementModalOpen(false);
        setLeaseAgreementDetailOpen(false);

        if (!!selectedLeaseId) {
            const selectedLease = [...(filteredLeases ?? [])].find(
                (lease) => selectedLeaseId === lease.id,
            );
            if (
                LeaseAgreementStatus.SIGNED === selectedLease?.status ||
                LeaseAgreementStatus.TERMINATED === selectedLease?.status
            ) {
                setLeaseAgreementDetailOpen(true);
            } else {
                setLeaseAgreementModalOpen(true);
            }
        }
    }, [selectedLeaseId]);

    useEffect(() => {
        // filter leases
        filterLeases(propertyFilter, statusFilter);
    }, [propertyFilter, statusFilter, leases]);

    const fetchLeaseAgreements = async () => {
        const leaseAgreements = await leaseStore.getOwnerLeaseAgreements();
        setLeases(leaseAgreements);

        return leaseAgreements;
    };

    const handleLeaseModalOpen = () => {
        if (!selectedLeaseId) return;
        setLeaseAgreementModalOpen(true);
    };

    const handleLeaseModalClose = () => {
        setLeaseAgreementModalOpen(false);
        setSelectedLeaseId(undefined);
    };

    const handleLeaseDetailOpen = () => {
        if (!selectedLeaseId) return;
        setLeaseAgreementDetailOpen(true);
    };

    const handleLeaseDetailClose = () => {
        setLeaseAgreementDetailOpen(false);
        setSelectedLeaseId(undefined);
    };

    const handleLeaseSnapshotSelected = (leaseId: string) => () => {
        setSelectedLeaseId(leaseId);
    };

    const handleStatusFilterChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        let filteredValues = event.target.value as string[];
        filteredValues =
            filteredValues.length === 0 ? LeaseStatuses.map((s) => s.key) : filteredValues;
        setStatusFilter(filteredValues);
    };

    const getStatusCategory = (lease: IdLeaseAgreement) => {
        switch (lease.status) {
            case LeaseAgreementStatus.DRAFT:
            case LeaseAgreementStatus.PENDING_TENANT_SIGNATURE: {
                return 'draft';
            }
            case LeaseAgreementStatus.SIGNED: {
                return lease.moveoutDate < Date.now() ? 'inactive' : 'active';
            }
            case LeaseAgreementStatus.TERMINATED:
            case LeaseAgreementStatus.CANCELLED: {
                return 'inactive';
            }
        }
    };

    const handlePropertyChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const filter = event.target.value as string;
        setPropertyFilter(filter);
    };

    const filterLeases = (filteredProperty: string, filteredStatuses: string[]) => {
        let filtered = [...(leases ?? [])];

        if (!!filteredProperty && filteredProperty !== 'all') {
            // filter by properties
            filtered = filtered.filter(
                (lease) => streetAddress(lease.listingInfo.address) === filteredProperty,
            );
        }
        if (filteredStatuses.length > 0) {
            // filter statuses
            filtered = filtered.filter((lease) =>
                filteredStatuses.find((f) => f === getStatusCategory(lease)),
            );
        }
        setFilteredLeases(filtered);
    };

    const getUniqueAddresses = () => {
        const uniqueAddresses: string[] = [];
        // loop through the leases
        leases?.forEach((lease) => {
            const key = streetAddress(lease.listingInfo.address);
            if (!key) return;
            if (!uniqueAddresses.find((addr) => addr === key)) {
                uniqueAddresses.push(key);
            }
        });

        return uniqueAddresses;
        // extract addresses
    };

    return (
        <>
            <Grid container spacing={2} direction='column'>
                <Grid item>
                    <Grid
                        container
                        spacing={2}
                        direction='row'
                        justifyContent='flex-start'
                        alignItems='center'
                    >
                        <Grid item>Filter:</Grid>
                        <Grid item>
                            <FormControl
                                variant='outlined'
                                size='small'
                                style={{ minWidth: '300px', maxWidth: '350px' }}
                            >
                                <Select
                                    id='propertyFilter'
                                    value={propertyFilter}
                                    onChange={handlePropertyChange}
                                    displayEmpty
                                    variant='outlined'
                                >
                                    <MenuItem value='all' key='all-properties'>
                                        All Properties
                                    </MenuItem>
                                    {getUniqueAddresses().map((addr) => (
                                        <MenuItem key={addr} value={addr}>
                                            {addr}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl
                                variant='outlined'
                                size='small'
                                style={{ minWidth: '250px', maxWidth: '300px' }}
                            >
                                <Select
                                    id='lease-status-filter'
                                    variant='outlined'
                                    multiple
                                    value={statusFilter}
                                    onChange={handleStatusFilterChange}
                                    renderValue={(selected) => (selected as string[]).join(', ')}
                                >
                                    {LeaseStatuses.map((status) => (
                                        <MenuItem key={status.key} value={status.key}>
                                            <Checkbox
                                                checked={
                                                    !!statusFilter.find((f) => f === status.key)
                                                }
                                            />
                                            <ListItemText primary={status.Text} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    {/* filtered leases */}
                    {filteredLeases?.length === 0 ? (
                        <Typography variant='subtitle1'>
                            {!!leases && leases?.length > 0
                                ? 'Could not find a lease that matches the filter'
                                : 'There are no leases available'}
                        </Typography>
                    ) : (
                        filteredLeases?.map((lease) => {
                            return (
                                <LeaseSnapshot
                                    key={lease.id}
                                    lease={lease}
                                    tenants={lease.tenantIds
                                        .filter((tId) => tenants.has(tId))
                                        .map((tId) => tenants.get(tId) as ITenantInfo)}
                                    tenantInvitationInfo={leaseInviteTenantInfo.get(lease.id)}
                                    isActive={selectedLeaseId === lease.id}
                                    onSelected={handleLeaseSnapshotSelected(lease.id)}
                                    viewer={LeaseAgreementViewer.LANDLORD}
                                    leaseStore={leaseStore}
                                />
                            );
                        })
                    )}
                </Grid>
            </Grid>

            <LeaseAgreementLandlordComponent
                leaseAgreementId={selectedLeaseId}
                isOpen={leaseAgreementModalOpen}
                onOpen={handleLeaseModalOpen}
                onClose={handleLeaseModalClose}
            />

            <LeaseAgreementDetailComponent
                leaseAgreementId={selectedLeaseId}
                isOpen={leaseAgreementDetailOpen}
                onOpen={handleLeaseDetailOpen}
                onClose={handleLeaseDetailClose}
                viewer={LeaseAgreementViewer.LANDLORD}
                refetchLeases={fetchLeaseAgreements}
                setSelectedLeaseId={setSelectedLeaseId}
            />
        </>
    );
});

export const LandlordLeasesPage: React.FC = observer(() => {
    const [openAddLeaseDialog, setOpenAddLeaseDialog] = useState<boolean>(false);
    const [openCompleteProfileDialog, setOpenCompleteProfileDialog] =
        React.useState<boolean>(false);
    const { userStore } = React.useContext(RootStoreContext);

    const handleAddExistingLease = async () => {
        const acctSetupSteps = await userStore.getAccountSetupSteps();

        if (!acctSetupSteps?.IDENTITY_VERIFIED || !acctSetupSteps.USER_PROFILE_COMPLETE) {
            setOpenCompleteProfileDialog(true);
            return;
        }
        setOpenAddLeaseDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenCompleteProfileDialog(false);
    };

    return (
        <>
            <AdminPageLayout
                header={
                    <Grid container justifyContent='space-between'>
                        <PageTitle>Leases</PageTitle>
                        <Button variant='outlined' color='primary' onClick={handleAddExistingLease}>
                            <AddIcon />
                            Add Existing Lease
                        </Button>
                    </Grid>
                }
                content={<Leases />}
            />

            <AddTenantLeaseInvite
                modalOpen={openAddLeaseDialog}
                closeModal={() => setOpenAddLeaseDialog(false)}
            />

            <CompleteAccountSetupDialog
                openCompleteProfileDialog={openCompleteProfileDialog}
                handleCloseDialog={handleCloseDialog}
            />
        </>
    );
});
