import { useContext, useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { BillStatus, IdBill } from 'realhaus-sdk';
import PaymentIcon from '@material-ui/icons/Payment';
import { RootStoreContext } from '../../../global/storeContext';
import { useStyles } from './billStyles';
import { observer } from 'mobx-react-lite';
import {
    Box,
    Grid,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@material-ui/core';
import { format } from 'date-fns';
import { IsValidNumber } from '../../../global/util';

const resolveBillStatus = (bill: IdBill) => {
    switch (bill.status) {
        case BillStatus.CANCELED:
            return 'Canceled';
        case BillStatus.PENDING:
            return bill.dueDate < Date.now() ? 'Overdue' : 'Pending';
        case BillStatus.PAID:
            return 'Paid';
        default:
            return 'Pending';
    }
};

export const LeaseBillSnapshot: React.FC<{ initBill: IdBill; onPayBillClicked: () => void }> =
    observer(({ initBill, onPayBillClicked }) => {
        const classes = useStyles();
        const { uiStore, leaseStore } = useContext(RootStoreContext);

        const [bill, setBill] = useState<IdBill>(initBill);

        const isBillCanceled = () => {
            return bill.status === BillStatus.CANCELED;
        };

        const billStatus = resolveBillStatus(bill);
        const payDisabled =
            bill.status === BillStatus.PAID ||
            bill.status === BillStatus.CANCELED ||
            !!bill.trxLogRef;

        useEffect(() => {
            leaseStore.subscribeToBill(initBill.leaseId, initBill.id, (b) => {
                if (!b) return;
                setBill({ id: initBill.id, ...b });
            });
        }, []);

        return (
            <>
                <Box
                    className={classes.cardParent}
                    style={{
                        backgroundColor: 'transparent',
                    }}
                >
                    <Grid container direction='row' spacing={2}>
                        <Grid item xs={12} md={3} className={classes.cardContent}>
                            <Typography
                                variant='body1'
                                className={
                                    isBillCanceled() ? classes.disabledColor : classes.successColor
                                }
                            >
                                <b>${bill.amount}</b>
                            </Typography>
                            <Typography variant='subtitle1' color='textSecondary'>
                                {bill.type.replace('_', ' ')}
                            </Typography>
                        </Grid>

                        <Grid item xs={4} md={2} className={classes.cardContent}>
                            <Typography
                                variant='body1'
                                style={{ textTransform: 'uppercase' }}
                                className={isBillCanceled() ? classes.disabledColor : ''}
                            >
                                <b>{billStatus.replace('_', ' ')}</b>
                            </Typography>
                            <Typography variant='subtitle1' color='textSecondary'>
                                STATUS
                            </Typography>
                        </Grid>

                        <Grid item xs={8} md={4}>
                            <Grid container direction='row' justifyContent='center'>
                                <Grid item xs={6} className={classes.cardContent}>
                                    <Typography
                                        variant='body1'
                                        style={{
                                            color:
                                                billStatus === 'Overdue'
                                                    ? 'red'
                                                    : isBillCanceled()
                                                    ? 'rgba(0, 0, 0, 0.26)'
                                                    : '',
                                        }}
                                    >
                                        <b>{format(bill.dueDate, 'PP')}</b>
                                    </Typography>
                                    <Typography variant='subtitle1' color='textSecondary'>
                                        DUE DATE
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Grid container justifyContent='center'>
                                <BillActionButtonForTenant
                                    disabled={payDisabled || uiStore.loading}
                                    onClicked={onPayBillClicked}
                                />
                                <BillActionButtonForLandlord
                                    bill={bill}
                                    disabled={payDisabled || uiStore.loading}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </>
        );
    });

const BillActionButtonForTenant: React.FC<{ disabled: boolean; onClicked: () => void }> = observer(
    ({ disabled, onClicked }) => {
        const { uiStore } = useContext(RootStoreContext);
        const classes = useStyles();

        return uiStore.isTenantMode() ? (
            <Button
                variant='outlined'
                color='primary'
                fullWidth
                className={classes.successColor}
                onClick={onClicked}
                disabled={disabled}
            >
                <PaymentIcon />
                Pay
            </Button>
        ) : (
            <></>
        );
    },
);

const BillActionButtonForLandlord: React.FC<{ bill: IdBill; disabled: boolean }> = observer(
    ({ bill, disabled }) => {
        const { uiStore, walletStore } = useContext(RootStoreContext);
        const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
        const classes = useStyles();

        const handleIsPaidBillPayment = () => {
            setOpenConfirmDialog(true);
        };

        const closeBillPaymentDialog = () => {
            setOpenConfirmDialog(false);
        };

        const numberToCurrency = (price: number) => {
            if (!IsValidNumber(price)) price = 0;
            return (
                <>
                    <NumericFormat
                        value={price}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix='$'
                        decimalSeparator='.'
                        fixedDecimalScale
                    />
                </>
            );
        };

        const handleMarkBillAsPaid = () => {
            uiStore.showLoading();
            closeBillPaymentDialog();
            walletStore
                .updateBillStatusAsPaid(bill.leaseId, bill.id)
                .then(() => {
                    bill.status = BillStatus.PAID;
                    uiStore.success('Bill confirmed paid');
                })
                .catch((err) => {
                    console.error(`Could not mark bill of amount ${bill.amount} as paid`);
                    console.error(err);
                })
                .finally(() => {
                    uiStore.hideLoading();
                });
        };

        return (
            <>
                {uiStore.isLandlordMode() && (
                    <Button
                        variant='outlined'
                        color='primary'
                        fullWidth
                        className={classes.successColor}
                        onClick={handleIsPaidBillPayment}
                        disabled={disabled}
                    >
                        <PaymentIcon />
                        Paid
                    </Button>
                )}

                <Dialog open={openConfirmDialog} onClose={closeBillPaymentDialog} fullWidth={true}>
                    {/* // Landlord Marks bill as paid confirmation dialog */}
                    <>
                        <DialogTitle id='alert-dialog-title'>
                            <Typography align='center' variant='h6'>
                                Mark {bill.type.replace('_', ' ').toLowerCase()} bill of{' '}
                                {numberToCurrency(bill.amount)} as paid?
                            </Typography>
                        </DialogTitle>
                        <DialogContent>
                            <Typography>
                                Marking this bill as paid may incur a service charge in accordance
                                with our payment, terms and services. <br />
                                <br /> Would you like to proceed with marking the{' '}
                                {bill.type.replace('_', ' ').toLowerCase()} bill of{' '}
                                {numberToCurrency(bill.amount)} as paid?
                            </Typography>
                        </DialogContent>
                        <DialogActions style={{ padding: '16px 24px' }}>
                            <Button onClick={closeBillPaymentDialog} color='primary'>
                                Cancel
                            </Button>
                            <Button
                                onClick={handleMarkBillAsPaid}
                                color='primary'
                                variant='contained'
                            >
                                Confirm
                            </Button>
                        </DialogActions>
                    </>
                </Dialog>
            </>
        );
    },
);
