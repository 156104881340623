export const leaseAgreementTemplateKeys = {
  LENGTH_OF_LEASE_MONTHS: 'LENGTH_OF_LEASE_MONTHS',
  START_DATE_OF_LEASE: 'START_DATE_OF_LEASE',
  END_DATE_OF_LEASE: 'END_DATE_OF_LEASE',
  RENT_AMOUNT: 'RENT_AMOUNT',
  RENT_DUE_DAY: 'RENT_DUE_DAY',
  NSF_FEE: 'NSF_FEE',
  LATE_RENT_FEE: 'LATE_RENT_FEE',
  LATE_RENT_GRACE_PERIOD_DAYS: 'LATE_RENT_GRACE_PERIOD_DAYS',
  SECURITY_DEPOSIT_AMOUNT: 'SECURITY_DEPOSIT_AMOUNT',
  SECURITY_DEPOSIT_RETURN_IN_DAYS: 'SECURITY_DEPOSIT_RETURN_IN_DAYS',
  LEASE_RENEW_DAYS: 'LEASE_RENEW_DAYS',
  MEDIATION_TIMELINE_BEFORE_ESCALATION_DAYS: 'MEDIATION_TIMELINE_BEFORE_ESCALATION_DAYS',
};

export const leaseAgreementTemplateDefaults = {
  LENGTH_OF_LEASE_MONTHS: '12',
  START_DATE_OF_LEASE: 'N/A',
  END_DATE_OF_LEASE: 'N/A',
  RENT_AMOUNT: 'N/A',
  RENT_DUE_DAY: 'FIRST (1st)',
  NSF_FEE: 'NSF_FEE',
  LATE_RENT_FEE: '25',
  LATE_RENT_GRACE_PERIOD_DAYS: '15',
  SECURITY_DEPOSIT_AMOUNT: 'N/A',
  SECURITY_DEPOSIT_RETURN_IN_DAYS: 'TEN (10)',
  LEASE_RENEW_DAYS: '60',
  MEDIATION_TIMELINE_BEFORE_ESCALATION_DAYS: '14',
}

export const LeaseTemplatePaths: { [ky: string]: string } = {
  'AB': '/resources/lease_templates/alberta.xml',
  'ON': '/resources/lease_templates/ontario.xml',
  'BC': '/resources/lease_templates/britishColumbia.xml'
}