import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { RootStoreContext } from '../../global/storeContext';
import { IDTransactionLog, TransactionStatus, TrxLogType } from 'realhaus-sdk';
import { Grid, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import PaymentIcon from '@mui/icons-material/Payment';
import WithdrawIcon from '@mui/icons-material/Paid';
import TransferIcon from '@mui/icons-material/SwapHoriz';

import StringUtils from '../../utils/string';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        trxIcon: {
            display: 'block',
            [theme.breakpoints.down(938)]: {
                display: 'none',
            },
        },
    }),
);

export const RecentTrxActivities: React.FC = observer(() => {
    const { walletStore, userStore } = React.useContext(RootStoreContext);
    const [transactions, setTransactions] = React.useState<IDTransactionLog[]>([]);

    useEffect(() => {
        const init = async () => {
            let trnx: IDTransactionLog[] = [];
            let lastDoc: any = null;

            while (trnx.length < 5) {
                const activities = await walletStore.getTrxActivities(lastDoc);
                if (!activities || activities.transactions.length === 0) {
                    break;
                }

                lastDoc = activities.lastDoc;
                trnx.push(...activities.transactions);
            }

            trnx = trnx.sort((a, b) => b.timestamp - a.timestamp).slice(0, 5);
            setTransactions(trnx);
        };
        init();
    }, []);

    const isReceiver = (trnx: IDTransactionLog) => trnx.receiverId === userStore.userId;
    const isSender = (trnx: IDTransactionLog) => trnx.senderId === userStore.userId;

    const classes = useStyles();
    const TrnxIcon = ({ trnx }: { trnx: IDTransactionLog }) => {
        if (trnx.type === TrxLogType.PAYMENT) {
            return <PaymentIcon color='primary' />;
        } else if (trnx.type === TrxLogType.WITHDRAW) {
            return <WithdrawIcon color='primary' />;
        } else {
            return <TransferIcon color='primary' />;
        }
    };

    const getTrnxSummaryText = (trnx: IDTransactionLog) => {
        console.log(userStore.userId);
        const statusSuccess = trnx.status === TransactionStatus.SUCCESS;
        const statusFailed = trnx.status === TransactionStatus.FAILED;
        const statusInProgress = !statusSuccess && !statusFailed;

        if (trnx.type === TrxLogType.PAYMENT) {
            if (!!trnx.meta?.billId && !!trnx.meta?.leaseId) {
                if (isReceiver(trnx)) {
                    // received bill payment
                    return 'Received Bill Payment';
                } else if (isSender(trnx)) {
                    // paid a bill
                    const suffix = statusSuccess
                        ? ''
                        : statusFailed
                        ? ' - Failed'
                        : ' - In Progress';
                    return `Paid Bill${suffix}`;
                } else {
                    return 'N/A';
                }
            }
        } else if (trnx.type === TrxLogType.WITHDRAW) {
            return statusSuccess
                ? 'Funds Withdrawn'
                : statusInProgress
                ? 'Withdraw InProgress'
                : 'Withdraw Failed';
        }
        return '';
    };

    const TrnxSummary = ({ trnx }: { trnx: IDTransactionLog }) => {
        return (
            <Grid item xs={12} md={12}>
                <Typography variant='body2'>
                    {StringUtils.getDateTimeFormat(trnx.timestamp)}
                </Typography>
                <Typography variant='body1'>{getTrnxSummaryText(trnx)}</Typography>
            </Grid>
        );
    };

    const TrnxAmount = ({ trnx }: { trnx: IDTransactionLog }) => {
        if (isReceiver(trnx) && trnx.type === TrxLogType.PAYMENT) {
            return <>{StringUtils.asCurrency(trnx.amount - (trnx.meta?.trxfees ?? 0))}</>;
        }
        return <>{StringUtils.asCurrency(trnx.amount)}</>;
    };

    return (
        <>
            {transactions.map((trnx, index) => {
                return (
                    <Grid
                        container
                        spacing={2}
                        key={index}
                        alignItems='center'
                        alignContent='center'
                    >
                        <Grid item xs={1} md={2} className={classes.trxIcon}>
                            <TrnxIcon trnx={trnx} />
                        </Grid>
                        <Grid item xs={7} md={6}>
                            <TrnxSummary trnx={trnx} />
                        </Grid>
                        <Grid item xs={4} md={4}>
                            <Typography variant='body1' align='right'>
                                <b>
                                    <TrnxAmount trnx={trnx} />
                                </b>
                            </Typography>
                        </Grid>
                    </Grid>
                );
            })}
        </>
    );
});
